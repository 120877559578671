<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8">
                <card-component titulo="Lista dos usuario">
                    <template v-slot:conteudo>
                        <div class="row">
                            <div class="col-4 mb-3">
                                <input-container-component titulo="E-mail" id="inputEmail">
                                    <input type="text" class="form-control" id="inputEmail"
                                        placeholder="Informe o Email" v-model="busca.email" @keyup="pesquisar()">
                                </input-container-component>
                            </div>
                            <div class="col mb-3" style="align-content: end;">
                                <button type="submit" class="btn btn-primary btn-sm float-end" data-bs-toggle="modal"
                                    data-bs-target="#modalUsuario">Adicionar</button>
                            </div>
                        </div>
                        <table-component :dados="usuario.data" componentpai="usuarios" :editar="{
                            visivel: true,
                            dataToggle: 'modal',
                            dataTarget: '#modalUsuarioAtualizar'
                        }" :remover="{
                            visivel: true,
                            dataToggle: 'modal',
                            dataTarget: '#modalUsuarioRemover'
                        }" :titulos="{
                            email: { titulo: 'E-mail', tipo: 'texto' },
                            caps_nome: { titulo: 'Caps', tipo: 'texto' }
                        }"></table-component>
                    </template>
                    <template v-slot:rodape>
                        <div class="row">
                            <div class="col-10">
                                <paginate-component>
                                    <li v-for="l, key in usuario.links" :key="key"
                                        :class="l.active ? 'page-item active' : 'page-item'"><a class="page-link"
                                            v-html="l.label" @click="paginacao(l)"></a></li>
                                </paginate-component>
                            </div>
                        </div>
                    </template>
                </card-component>
            </div>
        </div>
        <modal-component id="modalUsuario" titulo="Adicionar Usuario">
            <template v-slot:alertas>
                <alert-component tipo="success" :detalhes="transacaoDetalhes" titulo="Cadastro realizado com sucesso!"
                    v-if="transacaoStatus == 'sucesso'"></alert-component>
                <alert-component tipo="danger" :detalhes="transacaoDetalhes" titulo="Erro ao cadastrar."
                    v-if="transacaoStatus == 'erro'"></alert-component>
            </template>
            <template v-slot:conteudo>
                <div class="form-group">
                    <input-container-component titulo="Nome Usuario" id="novoNome">
                        <input type="text" class="form-control" id="novoNome" placeholder="Informe o Nome do Usuario"
                            v-model="nomeUser">
                    </input-container-component>
                </div>
                <div class="form-group">
                    <input-container-component titulo="Email Usuario" id="novoEmail">
                        <input type="email" class="form-control" id="novoEmail" placeholder="Informe o Email do Usuario"
                            v-model="emailUser">
                    </input-container-component>
                </div>
                <div class="form-group">
                    <input-container-component titulo="Senha Usuario" id="novoSenha">
                        <input type="password" class="form-control" id="novoSenha"
                            placeholder="Informe a senha do Usuario" v-model="passwordUser">
                    </input-container-component>
                </div>
                <div class="form-group">
                    <input-container-component titulo="Caps">
                        <select name="caps_usuario" class="form-control" id="caps_usuario" v-model="capsUser">
                            <option v-for="lista in listaCaps" :key="lista.id" :value="lista.id">
                                {{ lista.nome }}
                            </option>
                        </select> 
                    </input-container-component>
                </div>
                <div class="form-group">
                    <input-container-component titulo="Nivel de acesso">
                        <select name="nivel_acesso" class="form-control" id="nivel_acesso" v-model="nivel_acesso">
                            <option value="user">Usuario</option>
                            <option value="consulta">Consulta</option>
                            <option value="adm">Administrador</option>
                        </select>
                    </input-container-component>
                </div>
            </template>
            <template v-slot:rodape>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                <button type="button" class="btn btn-primary" @click="salvar()">Salvar</button>
            </template>
        </modal-component>

        <modal-component id="modalUsuarioAtualizar" titulo="Atualizar Usuario">
            <template v-slot:alertas>
                <alert-component tipo="success" :detalhes="$store.state.transacao.mensagem"
                    titulo="Cadastro realizado com sucesso!"
                    v-if="$store.state.transacao.status == 'sucesso'"></alert-component>
                <alert-component tipo="danger" :detalhes="$store.state.transacao.mensagem" titulo="Erro ao cadastrar."
                    v-if="$store.state.transacao.status == 'erro'"></alert-component>
            </template>
            <template v-slot:conteudo>
                <div class="form-group">
                    <input-container-component titulo="Nome Usuario" id="atualizarNome">
                        <input type="text" class="form-control" id="atualizarNome"
                            placeholder="Informe o Nome do Usuario" v-model="$store.state.item.name">
                    </input-container-component>
                </div>
                <div class="form-group">
                    <input-container-component titulo="Email Usuario" id="atualizarEmail">
                        <input type="email" class="form-control" id="atualizarEmail"
                            placeholder="Informe o Email do Usuario" v-model="$store.state.item.email">
                    </input-container-component>
                </div>
                <div class="form-group">
                    <input-container-component titulo="Senha Usuario" id="atualizarSenha">
                        <input type="password" class="form-control" id="atualizarSenha"
                            placeholder="Informe a senha do Usuario" v-model="passwordUser">
                    </input-container-component>
                </div>
                <div class="form-group">
                    <input-container-component titulo="Caps">
                        <select name="caps_usuario" class="form-control" id="caps_usuario"
                            v-model="$store.state.item.caps_id">
                            <option v-for="lista in listaCaps" :key="lista.id" :value="lista.id">
                                {{ lista.nome }}
                            </option>
                        </select>
                    </input-container-component>
                </div>
                <div class="form-group">
                    <input-container-component titulo="Nivel de acesso">
                        <select name="nivel_acesso" class="form-control" id="nivel_acesso"
                            v-model="$store.state.item.nivel_acesso">
                            <option value="user">Usuario</option>
                            <option value="consulta">Consulta</option>
                            <option value="adm">Administrador</option>
                        </select>
                    </input-container-component>
                </div>
            </template>
            <template v-slot:rodape>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                <button type="button" class="btn btn-primary" @click="atualizarUsuario()">Atualizar</button>
            </template>
        </modal-component>

        <modal-component id="modalUsuarioRemover" titulo="Remover Usuario">
            <template v-slot:alertas>
                <alert-component tipo="success" :detalhes="$store.state.transacao.mensagem"
                    titulo="Cadastro realizado com sucesso!"
                    v-if="$store.state.transacao.status == 'sucesso'"></alert-component>
                <alert-component tipo="danger" :detalhes="$store.state.transacao.mensagem" titulo="Erro ao cadastrar."
                    v-if="$store.state.transacao.status == 'erro'"></alert-component>
            </template>
            <template v-slot:conteudo>
                <div class="form-group">
                    <input-container-component titulo="Nome Usuario" id="removerNome">
                        <input type="text" class="form-control" id="removerNome" placeholder="Informe o Nome do Usuario"
                            disabled :value="$store.state.item.name">
                    </input-container-component>
                </div>
                <div class="form-group">
                    <input-container-component titulo="Email Usuario" id="removerEmail">
                        <input type="email" class="form-control" id="removerEmail"
                            placeholder="Informe o Email do Usuario" disabled :value="$store.state.item.email">
                    </input-container-component>
                </div>
                <div class="form-group">
                    <input-container-component titulo="Senha Usuario" id="removerSenha">
                        <input type="password" class="form-control" id="removerSenha"
                            placeholder="Informe a senha do Usuario" disabled>
                    </input-container-component>
                </div>
                <div class="form-group">
                    <input-container-component titulo="Caps">
                        <select name="caps_usuario" class="form-control" id="caps_usuario" disabled
                            :value="$store.state.item.caps_id">
                            <option v-for="lista in listaCaps" :key="lista.id" :value="lista.id">
                                {{ lista.nome }}
                            </option>
                        </select>
                    </input-container-component>
                </div>
                <div class="form-group">
                    <input-container-component titulo="Nivel de acesso">
                        <select name="nivel_acesso" class="form-control" id="nivel_acesso" disabled
                            :value="$store.state.item.nivel_acesso">
                            <option value="user">Usuario</option>
                            <option value="adm">Administrador</option>
                        </select>
                    </input-container-component>
                </div>
            </template>
            <template v-slot:rodape>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                <button type="button" class="btn btn-danger" @click="removerUsuario()"
                    v-if="$store.state.transacao.status != 'sucesso'">Remover</button>
            </template>
        </modal-component>
    </div>
</template>

<script>
export default {
    props: ['user'],
    data() {
        return {
            urlBase: 'https://printraas.candido.org.br/api/v1/user',
            urlPaginacao: '',
            urlFiltro: '',
            nomeUser: '',
            emailUser: '',
            passwordUser: '',
            capsUser: '',
            nivel_acesso: '',
            transacaoStatus: '',
            transacaoDetalhes: {},
            usuario: { data: [] },
            busca: { email: '', caps: '' },
            listaCaps: {},
            links: {}
        }
    },
    methods: {
        atualizarUsuario() {
            let formData = new FormData()
            formData.append('_method', 'patch')
            formData.append('name', this.$store.state.item.name)
            formData.append('email', this.$store.state.item.email)
            if (this.passwordUser) {
                formData.append('password', this.passwordUser)
            }
            formData.append('nivel_acesso', this.$store.state.item.nivel_acesso)
            formData.append('caps_id', this.$store.state.item.caps_id)


            let config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': this.token
                }
            }

            axios.post(this.urlBase + '/' + this.$store.state.item.id, formData, config)
                .then(response => {
                    this.carregarLista()
                    this.$store.state.transacao.status = 'sucesso'
                    this.$store.state.transacao.mensagem = response.data
                })
                .catch(errors => {
                    this.carregarLista()
                    this.$store.state.transacao.status = 'erro'
                    this.$store.state.transacao.mensagem = {
                        mensagem: errors.response.data.mensagem,
                        dados: errors.response.data.errors
                    }
                })
        },
        removerUsuario() {
            let confirmacao = confirm('Confirmar remoção do registro!')

            if (!confirmacao) return false

            let formData = new FormData()
            formData.append('_method', 'delete')

            let config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': this.token
                }
            }

            axios.post(this.urlBase + '/' + this.$store.state.item.id, formData, config)
                .then(response => {
                    this.carregarLista()
                    this.$store.state.transacao.status = 'sucesso'
                    this.$store.state.transacao.mensagem = response.data
                })
                .catch(errors => {
                    this.$store.state.transacao.status = 'erro'
                    this.$store.state.transacao.mensagem = {
                        mensagem: errors.response.data.mensagem,
                        dados: errors.response.data.errors
                    }
                })
        },
        pesquisar() {
            let filtro = ''
            for (let chave in this.busca) {
                if (this.busca[chave]) {
                    if (filtro != '') {
                        filtro += ';'
                    }
                    filtro += chave + ':like:' + this.busca[chave]
                }
            }
            if (filtro != '') {
                this.urlPaginacao = 'page=1'
                this.urlFiltro = '&filtro=' + filtro + '%'
            } else {
                this.urlFiltro = ''
            }
            this.carregarLista()
        },
        paginacao(l) {
            if (l.url) {
                this.urlPaginacao = l.url.split('?')[1]
                this.carregarLista()
            }
        },
        carregarLista() {
            let url = this.urlBase + '?' + this.urlPaginacao + this.urlFiltro
            let config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': this.token
                }
            }
            axios.get(url, config)
                .then(response => {
                    // this.links = response.data
                    this.usuario = {
                        ...response.data,
                        data: response.data.data.map(item => ({
                            id: item.id,
                            name: item.name,
                            email: item.email,
                            email_verified_at: item.email_verified_at,
                            nivel_acesso: item.nivel_acesso,
                            created_at: item.created_at,
                            updated_at: item.updated_at,
                            caps_id: item.caps?.id,
                            caps_nome: item.caps?.nome,
                            caps_cnes: item.caps?.cnes
                            // Adicione mais campos do objeto caps se necessário
                        }))
                    };
                })
                .catch(error => {
                    console.error("Erro na requisição:", error);
                });

        },
        carregaListaCaps() {
            let url = 'https://printraas.candido.org.br/api/v1/caps?apenas_nomes=id,nome' 

            let config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': this.token
                }
            }

            axios.get(url, config)
                .then(response => {
                    this.listaCaps = response.data
                })
                .catch(error => {
                    console.error("Erro na requisição:", error);
                });
        },
        salvar() {
            let formData = new FormData()

            formData.append('name', this.nomeUser)
            formData.append('email', this.emailUser)
            formData.append('password', this.passwordUser)
            formData.append('nivel_acesso', this.nivel_acesso)
            formData.append('caps_id', this.capsUser)


            let config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': this.token
                }
            }

            axios.post(this.urlBase, formData, config)
                .then(response => {
                    this.carregarLista()
                    this.transacaoStatus = 'sucesso'
                    this.transacaoDetalhes = {
                        mensagem: 'ID do registro: ' + response.data.id
                    }
                })
                .catch(errors => {
                    this.transacaoStatus = 'erro'
                    this.transacaoDetalhes = {
                        mensagem: errors.response.data.mensagem,
                        dados: errors.response.data.errors
                    }
                })
        }
    },
    mounted() {
        this.carregarLista()
        this.carregaListaCaps()
    },
    computed: {
        token() {
            let token = 'Bearer ' + document.cookie.split(';').find(indice => {
                return indice.includes('token=')
            }).split('=')[1]
            return token
        },

        userJson() {
            return JSON.parse(this.user);
        },
    },
}
</script>
