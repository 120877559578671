<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8">
                <card-component titulo="Lista dos profissional">
                    <template v-slot:conteudo>
                        <div class="row">
                            <div class="col mb-3">
                                <input-container-component titulo="CNS" id="inputCes">
                                    <input type="number" class="form-control" id="inputCes" placeholder="Informe o CNS"
                                        v-model="busca.cns" @keyup="pesquisar()">
                                </input-container-component>
                            </div>
                            <div class="col mb-3">
                                <input-container-component titulo="Nome" id="inputNome">
                                    <input type="text" class="form-control" id="inputNome" placeholder="Informe o Nome"
                                        v-model="busca.nome" @keyup="pesquisar()">
                                </input-container-component>
                            </div>
                        </div>
                        <table-component :dados="profissional.data" componentpai="profissionais" :editar="{
                            visivel: true,
                            dataToggle: 'modal',
                            dataTarget: '#modalProfissionalAtualizar'
                        }" :remover="{
                            visivel: true,
                            dataToggle: 'modal',
                            dataTarget: '#modalProfissionalRemover'
                        }" :titulos="{
			    id: { titulo: 'ID', tipo: 'texto' },
                            nome: { titulo: 'Nome', tipo: 'texto' },
                            cns_profissional: { titulo: 'Cns', tipo: 'texto' }
                        }"></table-component>
                    </template>
                    <template v-slot:rodape>
                        <div class="row">
                            <div class="col-10">
                                <paginate-component>
                                    <li v-for="l, key in profissional.links" :key="key"
                                        :class="l.active ? 'page-item active' : 'page-item'"><a class="page-link"
                                            v-html="l.label" @click="paginacao(l)"></a></li>
                                </paginate-component>
                            </div>
                            <div class="col-2">
                                <button type="button" class="btn btn-primary btn-sm float-end" data-bs-toggle="modal"
                                    data-bs-target="#modalProfissional">
                                    Adicionar
                                </button>
                            </div>
                        </div>
                    </template>
                </card-component>
            </div>
        </div>
        <modal-component id="modalProfissional" titulo="Adicionar Profissional">
            <template v-slot:alertas>
                <alert-component tipo="success" :detalhes="transacaoDetalhes" titulo="Cadastro realizado com sucesso!"
                    v-if="transacaoStatus == 'sucesso'"></alert-component>
                <alert-component tipo="danger" :detalhes="transacaoDetalhes" titulo="Erro ao cadastrar."
                    v-if="transacaoStatus == 'erro'"></alert-component>
            </template>
            <template v-slot:conteudo>
                <div class="form-group">
                    <input-container-component titulo="Nome Profissional" id="novoNome">
                        <input type="text" class="form-control" id="novoNome"
                            placeholder="Informe o Nome do Profissional" v-model="nomeProfissional">
                    </input-container-component>
                </div>
                <div class="form-group mt-3">
                    <input-container-component titulo="Cns Profissional" id="novoCns">
                        <input type="number" class="form-control" id="novoCns" placeholder="Informe o Cns Profissional"
                            v-model="numeroCns">
                    </input-container-component>
                </div>
            </template>
            <template v-slot:rodape>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                <button type="button" class="btn btn-primary" @click="salvar()">Salvar</button>
            </template>
        </modal-component>

        <modal-component id="modalProfissionalAtualizar" titulo="Atualizar Profissional">
            <template v-slot:alertas>
                <alert-component tipo="success" :detalhes="$store.state.transacao.mensagem"
                    titulo="Profissional atualizado com sucesso!"
                    v-if="$store.state.transacao.status == 'sucesso'"></alert-component>
                <alert-component tipo="danger" :detalhes="$store.state.transacao.mensagem" titulo="Erro ao atualizar."
                    v-if="$store.state.transacao.status == 'erro'"></alert-component>
            </template>
            <template v-slot:conteudo>
                <div class="form-group">
                    <input-container-component titulo="Nome Profissional" id="atualizarNome">
                        <input type="text" class="form-control" id="atualizarNome"
                            placeholder="Informe o Nome do Profissional" v-model="$store.state.item.nome">
                    </input-container-component>
                </div>
                <div class="form-group mt-3">
                    <input-container-component titulo="Cns Profissional" id="atualizarCns">
                        <input type="number" class="form-control" id="atualizarCns"
                            placeholder="Informe o Cns Profissional" v-model="$store.state.item.cns_profissional">
                    </input-container-component>
                </div>
            </template>
            <template v-slot:rodape>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                <button type="button" class="btn btn-primary" @click="atualizarProfissional()">Atualizar</button>
            </template>
        </modal-component>

        <modal-component id="modalProfissionalRemover" titulo="Remover Profissional">
            <template v-slot:alertas>
                <alert-component tipo="success" :detalhes="$store.state.transacao.mensagem"
                    titulo="Profissional removido com sucesso!"
                    v-if="$store.state.transacao.status == 'sucesso'"></alert-component>
                <alert-component tipo="danger" :detalhes="$store.state.transacao.mensagem" titulo="Erro ao remover."
                    v-if="$store.state.transacao.status == 'erro'"></alert-component>
            </template>
            <template v-slot:conteudo v-if="$store.state.transacao.status != 'sucesso'">
                <div class="form-group">
                    <input-container-component titulo="Nome Profissional" id="removerNome">
                        <input type="text" class="form-control" id="removerNome" :value="$store.state.item.nome"
                            disabled>
                    </input-container-component>
                </div>
                <div class="form-group mt-3">
                    <input-container-component titulo="Cns Profissional" id="removerCns">
                        <input type="number" class="form-control" id="removerCns"
                            :value="$store.state.item.cns_profissional" disabled>
                    </input-container-component>
                </div>
            </template>
            <template v-slot:rodape>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                <button type="button" class="btn btn-danger" @click="removerProfissional()"
                    v-if="$store.state.transacao.status != 'sucesso'">Remover</button>
            </template>
        </modal-component>
    </div>
</template>

<script>
export default {
    props: ['user'],
    data() {
        return {
            urlBase: 'https://printraas.candido.org.br/api/v1/profissional',
            urlPaginacao: '',
            urlFiltro: '',
            nomeProfissional: '',
            numeroCns: '',
            transacaoStatus: '',
            transacaoDetalhes: {},
            profissional: { data: [] },
            busca: { cns: '', nome: '' },
        }
    },
    methods: {
        resetTransacao() {
            this.transacaoStatus = ''
            this.transacaoDetalhes = ''
            this.nomeProfissional = ''
            this.numeroCns = ''
        },
        atualizarProfissional() {
            let formData = new FormData()
            formData.append('_method', 'patch')
            formData.append('nome', this.$store.state.item.nome)
            formData.append('cns_profissional', this.$store.state.item.cns_profissional)
            formData.append('caps_id', this.userJson.caps_id)


            let config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': this.token
                }
            }

            axios.post(this.urlBase + '/' + this.$store.state.item.id, formData, config)
                .then(response => {
                    this.carregarLista()
                    this.$store.state.transacao.status = 'sucesso'
                    this.$store.state.transacao.mensagem = response.data
                })
                .catch(errors => {
                    this.$store.state.transacao.status = 'erro'
                    this.$store.state.transacao.mensagem = {
                        mensagem: errors.response.data.mensagem,
                        dados: errors.response.data.errors
                    }
                })
        },
        removerProfissional() {
            let confirmacao = confirm('Confirmar remoção do registro!')

            if (!confirmacao) return false

            let formData = new FormData()
            formData.append('_method', 'delete')

            let config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': this.token
                }
            }

            axios.post(this.urlBase + '/' + this.$store.state.item.id, formData, config)
                .then(response => {
                    this.carregarLista()
                    this.$store.state.transacao.status = 'sucesso'
                    this.$store.state.transacao.mensagem = response.data
                })
                .catch(errors => {
                    this.$store.state.transacao.status = 'erro'
                    this.$store.state.transacao.mensagem = {
                        mensagem: errors.response.data.mensagem,
                        dados: errors.response.data.errors
                    }
                })
        },
        pesquisar() {
            let filtro = ''
            for (let chave in this.busca) {
                if (this.busca[chave]) {
                    if (filtro != '') {
                        filtro += ';'
                    }
                    filtro += chave + ':like:' + this.busca[chave]
                }
            }
            if (filtro != '') {
                this.urlPaginacao = 'page=1'
                this.urlFiltro = '&filtro=' + filtro + '%'
            } else {
                this.urlFiltro = ''
            }
            this.carregarLista()
        },
        paginacao(l) {
            if (l.url) {
                this.urlPaginacao = l.url.split('?')[1]
                this.carregarLista()
            }
        },
        carregarLista() {
            let url = this.urlBase + '?' + this.urlPaginacao + '&filtro=caps_id:=:' + this.userJson.caps_id + this.urlFiltro
            let config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': this.token
                }
            }
            axios.get(url, config)
                .then(response => {
                    this.profissional = response.data
                })
                .catch(errors => {
                })
        },
        salvar() {
            let formData = new FormData()

            formData.append('nome', this.nomeProfissional)
            formData.append('cns_profissional', this.numeroCns)
            formData.append('caps_id', this.userJson.caps_id)


            let config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': this.token
                }
            }

            axios.post(this.urlBase, formData, config)
                .then(response => {
                    this.carregarLista()
                    this.transacaoStatus = 'sucesso'
                    this.transacaoDetalhes = {
                        mensagem: 'ID do registro: ' + response.data.id
                    }
                })
                .catch(errors => {
                    console.log(errors.response)
                    this.transacaoStatus = 'erro'
                    this.transacaoDetalhes = {
                        mensagem: errors.response.data.mensagem,
                        dados: errors.response.data.errors
                    }
                })
        }
    },
    mounted() {
        this.carregarLista()
        // Obtém o elemento da modal pelo ID
        const modalElement = document.getElementById('modalProfissional');

        // Adiciona o evento de fechamento da modal para resetar as variáveis
        modalElement.addEventListener('hidden.bs.modal', this.resetTransacao);
    },
    beforeUnmount() {
        // Remove o evento ao desmontar o componente para evitar vazamento de memória
        const modalElement = document.getElementById('modalProfissional');
        modalElement.removeEventListener('hidden.bs.modal', this.resetTransacao);
    },
    computed: {
        token() {
            let token = 'Bearer ' + document.cookie.split(';').find(indice => {
                return indice.includes('token=')
            }).split('=')[1]
            return token
        },

        userJson() {
            return JSON.parse(this.user);
        },
    },
}
</script>
