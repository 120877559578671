<template>
    <div>
        <div class="row mb-3">
            <div class="col">
                <input-container-component titulo="Profissional Referência" id="editProfissionalReferencia">
                    <select name="editProfissionalReferencia" class="form-control" id="editProfissionalReferencia"
                        v-model="pdf_prof_referencia" @change="pdfProfRef()">
                        <option></option>
                        <option v-for="lista in listaprof" :key="lista.id" :value="lista.id+'-'+lista.nome">
                            {{ lista.nome }}
                        </option>
                    </select>
                </input-container-component>
            </div>
            <div class="col" style="align-content: end;">
                <button @click="gerarPdfs" :disabled="carregando" class="btn btn-danger">
                    {{ carregando ? "Gerando PDFs..." : "Gerar PDFs" }}
                </button>
            </div>
        </div>
        <hr>
        <h5>Gerar Todos os Ativos</h5>
        <button @click="gerarPdfs" :disabled="carregando" class="btn btn-danger">
            {{ carregando ? "Gerando PDFs..." : "Gerar PDFs" }}
        </button>

        <!-- Modal que bloqueia a interação -->
        <div v-if="carregando" class="modal-overlay">
            <div class="modal-content">
                <h3>Gerando PDFs...</h3>
                <p>Por favor, aguarde enquanto processamos os registros.</p>

                <!-- Barra de progresso -->
                <div class="progress-bar">
                    <div class="progress" :style="{ width: progressPercent + '%' }"></div>
                </div>
                <p>{{ processados }} de {{ totalRegistros }} registros processados.</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['capsid', 'listaprof'],
    data() {
        return {
            totalRegistros: 0,      // Total de registros no banco
            processados: 0,         // Quantidade de registros já processados
            limitePorPagina: 50,    // Quantidade de registros por PDF
            carregando: false,      // Estado do carregamento
            urlPdf: '/pdfgeral/' + this.capsid,
            urlProf: '',
            pdf_prof_referencia: ''
        }
    },
    methods: {
        pdfProfRef() {
            let url = 'https://printraas.candido.org.br/quantidade-ativos/' + this.capsid + '/' + this.pdf_prof_referencia
            
            let config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': this.token
                }
            } 
            axios.get(url, config)
                .then(response => {
                    this.totalRegistros = response.data.quantidade
                    this.urlProf += '/' + this.pdf_prof_referencia
                })
                .catch(errors => {
                    console.log(errors.response.data);
                    alert("Referencia não associada!");
                });
        },
        quantidadeAtivos() {
            let url = 'https://printraas.candido.org.br/quantidade-ativos/' + this.capsid
            
            let config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': this.token
                }
            }
            axios.get(url, config)
                .then(response => {
                    this.totalRegistros = response.data.quantidade
                    this.$store.state.indicativos.ativos = response.data.quantidade
                })
                .catch(error => {
                    console.error("Erro na requisição:", error);
                });
        },
        // Gera PDFs iterativamente
        async gerarPdfs() {
            this.carregando = true;

            while (this.processados < this.totalRegistros) {
                try {
                    const start = this.processados;
                    const limit = this.limitePorPagina;

                    // Faz a requisição para gerar o PDF
                    const response = await axios({
                        url: `${this.urlPdf}/${start}/${limit}/${this.urlProf}`,
                        method: "GET",
                        responseType: "blob", // Para tratar o PDF como arquivo binário
                    });

                    // Cria um link temporário para download do PDF
                    const blob = new Blob([response.data], { type: "application/pdf" });
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    let nomePdf = '';
                    a.href = url;
                    nomePdf = `fichas_${start}_${start + limit - 1}.pdf`;
                    if(this.pdf_prof_referencia) {
                        nomePdf = `referencia_${this.pdf_prof_referencia.split('-')[1]}.pdf`;
                    }
                    a.download = nomePdf;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);

                    // Atualiza a contagem de registros processados
                    this.processados += limit;

                } catch (error) {
                    console.error(`Erro ao gerar PDF para o intervalo ${this.processados}:`, error);
                    break; // Encerra o loop se houver erro
                }
            }

            if (this.processados >= this.totalRegistros) {
                alert("Todos os PDFs foram gerados e baixados com sucesso!");
            }
            this.carregando = false;
            this.pdf_prof_referencia = ''
            this.urlProf = ''
            this.processados = 0
            this.quantidadeAtivos()
        },
    },
    async mounted() {
        // Obtém o total de registros ao carregar o componente
        await this.quantidadeAtivos();
    },
    computed: {
        // Calcula a porcentagem de progresso
        progressPercent() {
            if (this.totalRegistros === 0) return 0;
            return Math.min(100, (this.processados / this.totalRegistros) * 100);
        },
    }

}
</script>
<style scoped>
/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

/* Modal Content */
.modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    width: 400px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

/* Progress Bar Container */
.progress-bar {
    width: 100%;
    height: 20px;
    background: #e0e0e0;
    border-radius: 10px;
    overflow: hidden;
    margin: 20px 0;
}

/* Progress Bar */
.progress {
    height: 100%;
    background: #4caf50;
    transition: width 0.3s ease;
}
</style>
