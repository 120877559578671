<template>
    <table class="table table-success table-striped table-hover">
        <thead>
            <tr><!-- class="text-uppercase" -->
                <th scope="col" v-for="titulo, key in titulos" :key="key">{{ titulo.titulo }}
                </th>
                <th v-if="editar.visivel || remover.visivel"></th>
            </tr> 
        </thead>
        <tbody>
            <tr v-for="obj, chave in dadosFiltrados" :key="chave">
                <td v-for="valor, chaveValor in obj" :key="chaveValor">
                    <span v-if="titulos[chaveValor].tipo == 'texto'">{{ valor }}</span>
                    <span v-if="titulos[chaveValor].tipo == 'data'">{{ valor }}</span>
                </td>
                <td v-if="editar || remover">
                    <a v-if="pdf && obj.status == 'Ativo'" :href="'https://printraas.candido.org.br/pdf/' + obj.id" target="_blank"
                        class="btn btn-outline-danger float-start" style="font-size: 11px;">PDF</a>
                    <button v-if="remover" class="btn btn-outline-danger btn-sm float-end"
                        :data-bs-toggle="remover.dataToggle" :data-bs-target="remover.dataTarget"
                        @click="setStore(obj)">Remover</button>
                    <button v-if="editar" class="btn btn-outline-primary btn-sm float-end" style="margin-right: 5px;"
                        :data-bs-toggle="editar.dataToggle" :data-bs-target="editar.dataTarget"
                        @click="setStore(obj)">Editar</button>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    props: ['dados', 'titulos', 'editar', 'remover', 'componentpai', 'pdf'],
    methods: {
        setStore(obj) {
            this.$store.state.transacao.status = ''
            this.$store.state.transacao.mensagem = ''
            if (this.componentpai == 'pacientes') {
                this.$store.state.item = this.dados.find(dado => dado.cns === obj.cns)
            } if (this.componentpai == 'usuarios') {
                this.$store.state.item = this.dados.find(dado => dado.email === obj.email)
            } if (this.componentpai == 'profissionais') {
                this.$store.state.item = this.dados.find(dado => dado.id === obj.id)
            } if (this.componentpai == 'caps') {
                this.$store.state.item = this.dados.find(dado => dado.id === obj.id)
            }


            function substituirNullPorZero(obj) {
                // Cria um novo objeto para evitar modificar o original
                const novoObjeto = Array.isArray(obj) ? [] : {};

                // Itera sobre cada chave do objeto
                for (let key in obj) {
                    // Se o valor é um objeto ou array, chama a função recursivamente
                    if (obj[key] !== null && typeof obj[key] === 'object') {
                        novoObjeto[key] = substituirNullPorZero(obj[key]);
                    } else {
                        // Substitui null por 0, caso contrário mantém o valor original
                        novoObjeto[key] = obj[key] === null ? '' : obj[key];
                    }
                }

                return novoObjeto;
            }
            this.$store.state.item = substituirNullPorZero(this.$store.state.item)
        },
    },
    mounted() {
    },
    computed: {
        dadosFiltrados() {
            let campos = Object.keys(this.titulos)
            let dadosFiltrados = []

            this.dados.map((item, chave) => {
                let itemFiltrado = {}
                campos.forEach(campo => {
                    itemFiltrado[campo] = item[campo]
                })
                dadosFiltrados.push(itemFiltrado)
            })

            return dadosFiltrados
        }
    }
}
</script>
