<template>
    <div class="card">
        <div class="card-header" style="background-color: #87CDD2;">{{ titulo }}
            <span style="float: right; display: flex;" v-if="$store.state.indicativos">
                <p style="font-weight: bold; margin: 5px; padding: 5px;">Ativos: {{ $store.state.indicativos.ativos }}</p>
                <p style="font-weight: bold; margin: 5px; padding: 5px;">Inativos: {{ $store.state.indicativos.inativos }}</p>
            </span>
        </div>

        <div class="card-body">
            <slot name="conteudo"></slot>
        </div>
        <div class="card-footer">
            <slot name="rodape"></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: ['titulo'],
    mounted() {
    }
}
</script>
