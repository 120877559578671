<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8">
                <div class="card">
                    <div class="card-header">Login</div>

                    <div class="card-body">
                        <alert-component tipo="success" :detalhes="$store.state.transacao.mensagem" titulo="sucesso!"
                            v-if="$store.state.transacao.status == 'sucesso'"></alert-component>
                        <alert-component tipo="danger" :detalhes="$store.state.transacao.mensagem" titulo="Erro!"
                            v-if="$store.state.transacao.status == 'erro'"></alert-component>
                        <form method="POST" action="" @submit.prevent="login">
                            <input type="hidden" name="_token" :value="csrf_token">
                            <div class="row mb-3">
                                <label for="email" class="col-md-4 col-form-label text-md-end">E-mail</label>

                                <div class="col-md-6">
                                    <input id="email" type="email" class="form-control" name="email" value="" required
                                        autocomplete="email" autofocus v-model="email">
                                </div>
                            </div>

                            <div class="row mb-3">
                                <label for="password" class="col-md-4 col-form-label text-md-end">Senha</label>

                                <div class="col-md-6">
                                    <input id="password" type="password" class="form-control" name="password" required
                                        autocomplete="current-password" v-model="password">
                                </div>
                            </div>

                            <div class="row mb-3">
                                <div class="col-md-6 offset-md-4">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" name="remember" id="remember">

                                        <label class="form-check-label" for="remember">
                                            Mantenha-me conectado
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-0">
                                <div class="col-md-8 offset-md-4">
                                    <button type="submit" class="btn btn-primary">
                                        Login
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['csrf_token'],
    data() {
        return {
            email: '',
            password: ''
        }
    },
    methods: {
        login(e) {
            let url = 'https://printraas.candido.org.br/api/login'
            let config = {
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams({
                    'email': this.email,
                    'password': this.password
                })
            }

            fetch(url, config)
                .then(response => {
                    if (!response.ok) {
                        // Se a resposta não for bem-sucedida, lança um erro
                        return response.json().then(err => {
                            throw err;
                        });
                    }
                    return response.json(); // Caso contrário, processa o JSON
                })
                .then(data => {
                    if (data.token) {
                        document.cookie = 'token=' + data.token + ';SameSite=Lax';
                        e.target.submit(); // Submete o formulário
                    }
                })
                .catch(errors => {
                    // Manipulação de erros
                    this.$store.state.transacao.status = 'erro';
                    this.$store.state.transacao.mensagem = {
                        mensagem: errors.mensagem || 'Usuário ou senha inválido!',
                        dados: errors.errors || null
                    };
                });
        }
    },
    mounted() {
    }
}
</script>
