/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue').default;

/* Importando e configurando o vuex */
// import Vuex from 'Vuex'
Vuex = require('vuex')

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        item: {},
        transacao: { status: '', mensagem: '' },
        indicativos: { ativos: '', inativos: ''}
    }
})

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('home-component', require('./components/Home.vue').default);
Vue.component('login-component', require('./components/Login.vue').default);
Vue.component('cadastro-paciente-component', require('./components/CadastroPaciente.vue').default);
Vue.component('caps-component', require('./components/Caps.vue').default);
Vue.component('lista-pacientes-component', require('./components/ListaPacientes.vue').default);
Vue.component('me-component', require('./components/Me.vue').default);
Vue.component('profissionais-component', require('./components/Profissionais.vue').default);
Vue.component('usuarios-component', require('./components/Usuarios.vue').default);
Vue.component('input-container-component', require('./components/InputContainer.vue').default);
Vue.component('table-component', require('./components/Table.vue').default);
Vue.component('card-component', require('./components/Card.vue').default);
Vue.component('modal-component', require('./components/Modal.vue').default);
Vue.component('alert-component', require('./components/Alert.vue').default);
Vue.component('paginate-component', require('./components/Paginate.vue').default);
Vue.component('auto-complete-component', require('./components/AutoComplete.vue').default);
Vue.component('loader-pdf-component', require('./components/LoaderPdf.vue').default);
Vue.component('pdf-geral-component', require('./components/PdfGeral.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
    store
});
