<template>
    <div>
        <input type="text" class="form-control" :id="id_input" :placeholder="titulo" :value="value"
            ref="inputautocomplete" />
        <div :id="id_div" class="autocomplete-suggestions"></div>
    </div>
</template>

<script>
import { cid_10_f_array1 } from '../cid_10_f_array1';
import { cid_10_f_array2 } from '../cid_10_f_array2';

export default {
    props: ['titulo', 'valuemodel', 'id_input', 'id_div', 'value'],
    data() {
        return {
            valorRef: ''
        }
    },
    methods: {
        obterValorRef() {
            return this.$refs.inputautocomplete.value
        },
        setStore(valor) {
            if (this.valuemodel == 'cid10princ') {
                this.$store.state.item.cid10_principal = valor
            }
            if (this.valuemodel == 'cid10assoc') {
                this.$store.state.item.cid10_causas_associadas = valor
            }
            if (this.valuemodel == 'centrosaude') {
                this.$store.state.item.centro_saude = valor
            }
        },
        showSuggestions() {
            let separaArray = []
            if (this.valuemodel == 'centrosaude') {
                separaArray = ["Situação de Rua", "Santa Mônica", "Boa Vista", "Eulina", "Aurélia", "Barão Geraldo", "Anchieta", "São Marcos", "Sta. Bárbara", "Cássio Raposo do Amaral", "Village", "Rosália", "San Martin", "Vila Rica", "Orozimbo Maia", "Esmeraldina", "Figueira", "São José", "São Vicente", "Faria Lima", "Santa Odila", "Vila Ipê", "Paranapanema", "São Domingos", "Carvalho de Moura", "Campo Belo", "Fernanda", "Nova América", "Oziel", "Conceição", "Costa e Silva", "São Quirino", "31 de março", "Taquaral", "Sousas", "Joaquim Egídio", "Centro", "Carlos Gomes", "Boa Esperança", "União dos Bairros", "Santa Lúcia", "Aeroporto", "Campos Elíseos (Tancredão)", "Vista Alegre", "Capivari", "Dic I", "Dic III", "São Cristóvão", "Itatinga", "V. União / CAIC", "Santo Antônio", "Perseu", "Integração", "Valença", "Florence", "Pedro Aquino (Balão)", "Ipaussurama", "Floresta", "Itajaí", "Rossin", "Santa Rosa", "Satélite Iris", "Lisa", "Campina Grande"]
            } else {
                separaArray = (cid_10_f_array1() + cid_10_f_array2()).split(',')
            }
            const suggestionsArray = separaArray
            const input = document.getElementById(this.id_input);
            const suggestionsBox = document.getElementById(this.id_div);

            input.addEventListener("input", (event) => {
                const query = event.target.value.toLowerCase();
                suggestionsBox.innerHTML = ""; // Limpar sugestões anteriores

                if (query) {
                    const filteredSuggestions = suggestionsArray.filter(item =>
                        item.toLowerCase().includes(query)
                    );

                    filteredSuggestions.forEach(suggestion => {
                        const suggestionDiv = document.createElement("div");
                        suggestionDiv.textContent = suggestion;
                        suggestionDiv.style.padding = "8px";
                        suggestionDiv.style.cursor = "pointer";
                        suggestionDiv.style.borderBottom = "1px solid #000";
                        suggestionDiv.addEventListener("click", () => {
                            input.value = suggestion; // Define o valor do input com a sugestão escolhida
                            suggestionsBox.innerHTML = ""; // Limpa as sugestões
                            this.setStore(suggestion); // `this` agora se refere ao componente Vue
                            this.$refs.inputautocomplete.value = suggestion
                        });
                        suggestionsBox.appendChild(suggestionDiv);
                    });
                }
            });

            input.addEventListener("blur", () => {
                // Salvar o valor digitado manualmente
                this.setStore(input.value);
            });

            document.addEventListener("click", (e) => {
                if (!suggestionsBox.contains(e.target) && e.target !== input) {
                    suggestionsBox.innerHTML = ""; // Esconde as sugestões ao clicar fora
                }
            });
        }
    },
    mounted() {
        this.showSuggestions()
    }
}
</script>
<style>
.autocomplete-suggestions {
    border: 1px solid #ccc;
    max-height: 150px;
    overflow-y: auto;
    position: absolute;
    background-color: #fff;
    z-index: 1000;
    width: auto;
    /* cursor: pointer; */
}

.autocomplete-suggestions div:hover {
    background-color: #e0e0e0;
}

.suggestion {
    padding: 8px;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
    /* Separador entre sugestões */
}

.suggestion:hover {
    background-color: #f0f0f0;
    /* Cor de fundo ao passar o mouse */
}
</style>
