<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="box" @mousedown="startDrag" :style="{ top: `${position.y}px`, left: `${position.x}px` }">
                <alert-component tipo="success" :detalhes="transacaoDetalhes" titulo="Cadastro realizado com sucesso!"
                    v-if="transacaoStatus == 'sucesso'"></alert-component>
                <alert-component tipo="danger" :detalhes="transacaoDetalhes" titulo="Erro ao cadastrar."
                    v-if="transacaoStatus == 'erro'"></alert-component>
            </div>
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <div class="row">
                            <div class="col">
                                <p>Cadastro de Paciente</p>
                            </div>
                            <div class="col">
                                <button type="button" class="btn btn-primary float-end"
                                    @click="salvar()">Cadastrar</button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row mb-4">
                            <div class="col">
                                <input-container-component titulo="Caps">
                                    <input type="text" class="form-control" :value="capsJson.nome" disabled>
                                    <input type="hidden" name="caps" :value="capsJson.nome">
                                </input-container-component>
                            </div>
                            <div class="col">
                                <input-container-component titulo="Cnes">
                                    <input type="text" class="form-control" :value="capsJson.cnes" disabled>
                                    <input type="hidden" name="cnes" :value="capsJson.cnes">
                                </input-container-component>
                            </div>
                            <div class="col">
                                <input-container-component titulo="Número do prontuário" id="numeroProntuario">
                                    <input type="text" class="form-control" id="numeroProntuario"
                                        placeholder="Informe o número do prontuário" v-model="n_prontuario">
                                </input-container-component>
                            </div>
                            <div class="col">
                                <input-container-component titulo="Mês/Ano Atendimento" id="mesAnoAtendimento">
                                    <input type="text" class="form-control" id="mesAnoAtendimento"
                                        placeholder="Informe o mês/ano de antendimento" v-model="mes_ano_atendimento">
                                </input-container-component>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col">
                                <input-container-component titulo="Nome Usuario" id="novoNome">
                                    <input type="text" class="form-control" id="novoNome"
                                        placeholder="Informe o Nome do Usuario" v-model="nome_usuario">
                                </input-container-component>
                            </div>
                            <div class="col">
                                <input-container-component titulo="Cns" id="novoCns">
                                    <input type="text" class="form-control" id="novoCns"
                                        placeholder="Informe o Cns do Usuario" v-model="cns">
                                </input-container-component>
                            </div>
                            <div class="col">
                                <input-container-component titulo="Data Admissão Usuario" id="novoDataAdmissao">
                                    <input type="text" class="form-control" id="novoDataAdmissao"
                                        placeholder="Informe a Data de Admissão" v-model="admissao">
                                </input-container-component>
                            </div>
                            <div class="col">
                                <input-container-component titulo="Data nascimento" id="novoDataNascimento">
                                    <input type="text" class="form-control" id="novoDataNascimento"
                                        placeholder="Informe a Data de Nascimento" v-model="nascimento">
                                </input-container-component>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col">
                                <input-container-component titulo="Nome da mãe" id="novoNomeMae">
                                    <input type="text" class="form-control" id="novoNomeMae"
                                        placeholder="Informe o Nome da Mãe" v-model="nome_mae">
                                </input-container-component>
                            </div>
                            <div class="col">
                                <input-container-component titulo="Nome Responsavel" id="novoResponsavel">
                                    <input type="text" class="form-control" id="novoResponsavel"
                                        placeholder="Informe o Nome do Responsavel" v-model="responsavel">
                                </input-container-component>
                            </div>
                            <div class="col">
                                <input-container-component titulo="Raça / Cor" id="novoRacaCor">
                                    <input type="text" class="form-control" id="novoRacaCor"
                                        placeholder="Informe a Raça / Cor" v-model="raca_cor">
                                </input-container-component>
                            </div>
                            <div class="col">
                                <input-container-component titulo="Etnia Indigena" id="novoEtniaIndigena">
                                    <input type="text" class="form-control" id="novoEtniaIndigena"
                                        placeholder="Informe Etinia Indigena" v-model="etnia_indigena">
                                </input-container-component>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col">
                                <input-container-component titulo="Sexo" id="novoSexo">
                                    <select name="novoSexo" class="form-control" id="novoSexo" v-model="genero">
                                        <option value="F">Feminino</option>
                                        <option value="M">Masculino</option>
                                    </select>
                                </input-container-component>
                            </div>
                            <div class="col">
                                <input-container-component titulo="Telefone de Contato" id="novoTelefoneContato">
                                    <input type="text" class="form-control" id="novoTelefoneContato"
                                        placeholder="Informe o Telefone de Contato" v-model="telefone">
                                </input-container-component>
                            </div>
                            <div class="col">
                                <input-container-component titulo="Centro de Saúde" id="novoCentroSaude">
                                    <auto-complete-component titulo="Informe o Centro de Saúde"
                                        id_input="autocompletecentrosaude" id_div="divcentrosaude"
                                        valuemodel="centrosaude" v-model="centro_saude"
                                        ref="inputcensaud"></auto-complete-component>
                                </input-container-component>
                            </div>
                            <div class="col">
                                <input-container-component titulo="Endereço" id="novoEndereco">
                                    <input type="text" class="form-control" id="novoEndereco"
                                        placeholder="Informe o Endereço" v-model="endereco">
                                </input-container-component>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col">
                                <input-container-component titulo="Municipio de Residência" id="novoMunResidencia">
                                    <input type="text" class="form-control" id="novoMunResidencia"
                                        placeholder="Informe o Municipio de residencia" v-model="mun_res">
                                </input-container-component>
                            </div>
                            <div class="col">
                                <input-container-component titulo="Cep" id="novoCep">
                                    <input type="text" class="form-control" id="novoCep" placeholder="Informe o Cep"
                                        v-model="cep">
                                </input-container-component>
                            </div>
                            <div class="col">
                                <input-container-component titulo="Nacionalidade" id="novoNacionalidade">
                                    <input type="text" class="form-control" id="novoNacionalidade"
                                        placeholder="Informe a Nacionalidade" v-model="nacionalidade">
                                </input-container-component>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col">
                                <input-container-component titulo="Usuário de Álcool e Outras Drogas"
                                    id="novoAlcoolDrogas">
                                    <br>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" id="editSim"
                                            v-model="alcool_drogas">
                                        <label class="form-check-label" for="editSim">Sim</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" id="editNao"
                                            v-model="alcool_drogas_n">
                                        <label class="form-check-label" for="editNao">Não</label>
                                    </div>
                                    <p>se Sim:</p>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" id="editAlcool"
                                            v-model="alcool">
                                        <label class="form-check-label" for="editAlcool">Alcool</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" id="editCrack" v-model="crack">
                                        <label class="form-check-label" for="editCrack">Crack</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" id="editCannabis"
                                            v-model="cannabis">
                                        <label class="form-check-label" for="editCannabis">Cannabis</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" id="editCocaina"
                                            v-model="cocaina">
                                        <label class="form-check-label" for="editCocaina">Cocaina</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" id="novoOutras"
                                            v-model="outras_drogas" disabled>
                                        <label class="form-check-label" for="novoOutras">Outras</label>
                                    </div>
                                </input-container-component>
                            </div>
                            <div class="col">
                                <input-container-component titulo="Outras Drogas" id="novoOutrasDrogas">
                                    <input type="text" class="form-control" id="novoOutrasDrogas"
                                        placeholder="Informe as Outras Drogas" v-model="outras_drogas">
                                </input-container-component>
                            </div>
                            <div class="col">
                                <input-container-component titulo="Situação de Rua" id="novoSituacaoRua">
                                    <select name="novoSituacaoRua" class="form-control" id="novoSituacaoRua"
                                        v-model="situacao_rua">
                                        <option value="S">Sim</option>
                                        <option value="N">Não</option>
                                    </select>
                                </input-container-component>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col">
                                <input-container-component titulo="Encaminhamento" id="novoEncaminhamento">
                                    <br>
                                    <br>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" id="editContAcompCaps"
                                            v-model="encaminhamento_continuidade_caps">
                                        <label class="form-check-label" for="editContAcompCaps">Continuidade do Acomp.
                                            em
                                            CAPS</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" id="editContAtenBasic"
                                            v-model="encaminhamento_continuidade_basica">
                                        <label class="form-check-label" for="editContAtenBasic">Continuidade na Atenção
                                            Básica</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" id="editAlta"
                                            v-model="encaminhamento_alta">
                                        <label class="form-check-label" for="editAlta">Alta</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" id="editObito"
                                            v-model="encaminhamento_obito">
                                        <label class="form-check-label" for="editObito">Óbito</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" id="editAbandono"
                                            v-model="encaminhamento_abandono">
                                        <label class="form-check-label" for="editAbandono">Abandono</label>
                                    </div>
                                </input-container-component>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col">
                                <input-container-component titulo="Origem" id="novoOrigem">
                                    <br>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" id="editDemandaEspo"
                                            v-model="demanda_espontanea">
                                        <label class="form-check-label" for="editDemandaEspo">Demanda Espntânea</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" id="editUbs"
                                            v-model="ubs">
                                        <label class="form-check-label" for="editUbs">UBS</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" id="editServUE"
                                            v-model="serv_u_e">
                                        <label class="form-check-label" for="editServUE">Serv. U/E</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" id="editOutroCaps"
                                            v-model="outro_caps">
                                        <label class="form-check-label" for="editOutroCaps">Outro Caps</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" id="editHospGeral"
                                            v-model="hosp_geral">
                                        <label class="form-check-label" for="editHospGeral">Hospital Geral</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" id="editHospGeral"
                                            v-model="hosp_psiquiatra">
                                        <label class="form-check-label" for="editHospGeral">Hospital
                                            Psiquiátrico</label>
                                    </div>
                                </input-container-component>
                            </div>
                            <div class="col">
                                <input-container-component titulo="Origem Outros" id="novoOrigemOutros">
                                    <input type="text" class="form-control" id="novoOrigemOutros"
                                        placeholder="Informe a Origem Outros" v-model="origem_outros">
                                </input-container-component>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col">
                                <input-container-component titulo="Existe Estratégia de Saúde da Familia:"
                                    id="novoEstrategiaSaudeFamilia">
                                    <select name="novoEstrategiaSaudeFamilia" class="form-control"
                                        id="novoEstrategiaSaudeFamilia" v-model="estrategia_sau_familiar">
                                        <option value="S">Sim</option>
                                        <option value="N">Não</option>
                                    </select>
                                </input-container-component>
                            </div>
                            <div class="col">
                                <input-container-component titulo="Cnes CTS" id="novoCnesCts">
                                    <input type="text" class="form-control" id="novoCnesCts"
                                        placeholder="Número do Cnes CTS" v-model="cnes_cts">
                                </input-container-component>
                            </div>
                            <div class="col">
                                <input-container-component titulo="Profissional Referência"
                                    id="novoProfissionalReferencia">
                                    <select name="novoProfissionalReferencia" class="form-control"
                                        id="novoProfissionalReferencia" v-model="prof_referencia">
					<option value=""></option>
                                        <option v-for="lista in listaNomesProfissionais" :key="lista.id" :value="lista.id">
                                            {{ lista.nome }}
                                        </option>
                                    </select>
                                </input-container-component>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col">
                                <input-container-component titulo="CID10 Principal" id="novoCid10Principal">
                                    <auto-complete-component titulo="Informe CID 10 Principal"
                                        id_input="autocomplete_princ" id_div="divprincp" v-model="cid10_principal"
                                        ref="inputaprinc"></auto-complete-component>
                                </input-container-component>
                            </div>
                            <div class="col">
                                <input-container-component titulo="CID 10 Causas Associadas" id="novoCid10CausAssoc">
                                    <auto-complete-component titulo="Informe CID 10 Causas Associadas"
                                        id_input="autocomplete_assoc" id_div="divcausassoc"
                                        v-model="cid10_causas_associadas" ref="inputassoc"></auto-complete-component>
                                </input-container-component>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['user', 'caps_props'],
    data() {
        return {
            urlBase: 'https://printraas.candido.org.br/api/v1/ficha',
            n_prontuario: '',
            cnes: '',
            cnes_cts: '',
            caps: '',
            mes_ano_atendimento: '',
            cns: '',
            nome_usuario: '',
            admissao: '',
            nascimento: '',
            nome_mae: '',
            responsavel: '',
            raca_cor: '',
            etnia_indigena: '',
            genero: '',
            centro_saude: '',
            telefone: '',
            endereco: '',
            mun_res: '',
            cep: '',
            nacionalidade: '',
            situacao_rua: '',
            origem_outros: '',
            alcool: '',
            crack: '',
            cocaina: '',
            cannabis: '',
            outras_drogas: '',
            demanda_espontanea: '',
            ubs: '',
            serv_u_e: '',
            alcool_drogas: '',
            alcool_drogas_n: '',
            outro_caps: '',
            hosp_geral: '',
            hosp_psiquiatra: '',
            cid10_principal: '',
            cid10_causas_associadas: '',
            estrategia_sau_familiar: '',
            encaminhamento_continuidade_caps: '',
            encaminhamento_continuidade_basica: '',
            encaminhamento_alta: '',
            encaminhamento_obito: '',
            encaminhamento_abandono: '',
            prof_referencia: '',
            status_paciente: '',
            caps_id: '',
            transacaoStatus: '',
            transacaoDetalhes: {},
            listaNomesProfissionais: {},
            isDragging: false,
            position: { x: 0, y: 50 },      // Posição inicial da div
            offset: { x: 0, y: 50 }
        }
    },
    methods: {
        listaProfissionais() {
            let url = 'https://printraas.candido.org.br/api/v1/profissional?apenas_nomes=id,nome,cns_profissional,caps_id' + '&filtro=caps_id:=:' + this.userJson.caps_id

            let config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': this.token
                }
            }

            axios.get(url, config)
                .then(response => {
                    this.listaNomesProfissionais = response.data
                })
                .catch(error => {
                    console.error("Erro na requisição:", error);
                });
        },
        salvar() {
            this.cid10_principal = this.$refs.inputaprinc.obterValorRef()
            this.cid10_causas_associadas = this.$refs.inputassoc.obterValorRef()
            this.centro_saude = this.$refs.inputcensaud.obterValorRef()

            let formData = new FormData()

            formData.append('n_prontuario', this.n_prontuario)
            formData.append('cnes', this.capsJson.cnes)
            formData.append('cnes_cts', this.cnes_cts)
            formData.append('caps', this.capsJson.nome)
            formData.append('mes_ano_atendimento', this.mes_ano_atendimento)
            formData.append('cns', this.cns)
            formData.append('nome_usuario', this.nome_usuario)
            formData.append('admissao', this.admissao)
            formData.append('nascimento', this.nascimento)
            formData.append('nome_mae', this.nome_mae)
            formData.append('responsavel', this.responsavel)
            formData.append('raca_cor', this.raca_cor)
            formData.append('etnia_indigena', this.etnia_indigena)
            formData.append('genero', this.genero)
            formData.append('centro_saude', this.centro_saude)
            formData.append('telefone', this.telefone)
            formData.append('endereco', this.endereco)
            formData.append('mun_res', this.mun_res)
            formData.append('cep', this.cep)
            formData.append('nacionalidade', this.nacionalidade)
            formData.append('situacao_rua', this.situacao_rua)
            formData.append('origem_outros', this.origem_outros)
            formData.append('alcool_drogas', this.alcool_drogas == null ? '' : this.alcool_drogas)
            formData.append('alcool_drogas_n', this.alcool_drogas_n == null ? '' : this.alcool_drogas_n)
            formData.append('alcool', this.alcool == null ? '' : this.alcool)
            formData.append('crack', this.crack == null ? '' : this.crack)
            formData.append('cocaina', this.cocaina == null ? '' : this.cocaina)
            formData.append('cannabis', this.cannabis == null ? '' : this.cannabis)
            formData.append('outras_drogas', this.outras_drogas == null ? '' : this.outras_drogas)
            formData.append('demanda_espontanea', this.demanda_espontanea == null ? '' : this.demanda_espontanea)
            formData.append('ubs', this.ubs == null ? '' : this.ubs)
            formData.append('serv_u_e', this.serv_u_e == null ? '' : this.serv_u_e)
            formData.append('outro_caps', this.outro_caps == null ? '' : this.outro_caps)
            formData.append('hosp_geral', this.hosp_geral == null ? '' : this.hosp_geral)
            formData.append('hosp_psiquiatra', this.hosp_psiquiatra == null ? '' : this.hosp_psiquiatra)
            formData.append('cid10_principal', this.cid10_principal)
            formData.append('cid10_causas_associadas', this.cid10_causas_associadas)
            formData.append('estrategia_sau_familiar', this.estrategia_sau_familiar)
            formData.append('encaminhamento_continuidade_caps', this.encaminhamento_continuidade_caps == null ? '' : this.encaminhamento_continuidade_caps)
            formData.append('encaminhamento_continuidade_basica', this.encaminhamento_continuidade_basica == null ? '' : this.encaminhamento_continuidade_basica)
            formData.append('encaminhamento_alta', this.encaminhamento_alta == null ? '' : this.encaminhamento_alta)
            formData.append('encaminhamento_obito', this.encaminhamento_obito == null ? '' : this.encaminhamento_obito)
            formData.append('encaminhamento_abandono', this.encaminhamento_abandono == null ? '' : this.encaminhamento_abandono)
            formData.append('prof_referencia', this.prof_referencia)
            formData.append('status_paciente', 'Ativo')
            formData.append('caps_id', this.userJson.caps_id)

            console.log(formData)

            let config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': this.token
                }
            }

            axios.post(this.urlBase, formData, config)
                .then(response => {
                    this.transacaoStatus = 'sucesso'
                    this.transacaoDetalhes = {
                        mensagem: 'ID do registro: ' + response.data.id
                    }
                    // this.zerarCampos()
                })
                .catch(errors => {
                    console.log(errors.response)
                    this.transacaoStatus = 'erro'
                    this.transacaoDetalhes = {
                        mensagem: errors.response.data.mensagem,
                        dados: errors.response.data.errors
                    }
                })
        },
        startDrag(event) {
            // Define o movimento como ativo
            this.isDragging = true;

            // Calcula a posição inicial do cursor dentro da div
            this.offset.x = event.clientX - this.position.x;
            this.offset.y = event.clientY - this.position.y;

            // Adiciona listeners de movimento e soltura
            document.addEventListener('mousemove', this.drag);
            document.addEventListener('mouseup', this.stopDrag);
        },
        drag(event) {
            if (this.isDragging) {
                // Atualiza a posição da div baseada na posição do cursor
                this.position.x = event.clientX - this.offset.x;
                this.position.y = event.clientY - this.offset.y;
            }
        },
        stopDrag() {
            // Desativa o movimento e remove os listeners
            this.isDragging = false;
            document.removeEventListener('mousemove', this.drag);
            document.removeEventListener('mouseup', this.stopDrag);
        },
        zerarCampos() {
            setTimeout(() => {
                window.location.reload(true)
            }, 1000)
        }
    },
    mounted() {
        this.listaProfissionais()
    },
    computed: {
        token() {
            let token = 'Bearer ' + document.cookie.split(';').find(indice => {
                return indice.includes('token=')
            }).split('=')[1]
            return token
        },

        userJson() {
            return JSON.parse(this.user);
        },

        capsJson() {
            return JSON.parse(this.caps_props);
        },
    },
}
</script>
<style scoped>
.box {
    position: absolute;
    /* Para permitir o posicionamento baseado em top/left */
    cursor: move;
    /* Ícone de cursor de movimento */
    /* Adicione outros estilos aqui, como tamanho e cor */
    width: auto;
    z-index: 1;
    font-size: 12px;
    font-weight: bolder;
    opacity: 0.9;
}
</style>
