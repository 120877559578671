<template>
<div class="container">
    <div class="row justify-content-center">
        <div class="col-md-8">
            <div class="card">
                <div class="card-header">Bem-vindo</div>

                <div class="card-body">
                    Bem-vindo ao Sistema de Impressões de formulários RAAS
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
    export default {
    }
</script>
