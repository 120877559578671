<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12 p-0">
                <card-component titulo="Lista dos pacientes"> 
                    <template v-slot:conteudo>
                        <div class="row">
                            <div class="col-2 mb-2">
                                <input-container-component titulo="Nome" id="inputNome">
                                    <input type="text" class="form-control" id="inputNome" placeholder="Informe o Nome"
                                        v-model="busca.nome_usuario" @keyup="pesquisar()">
                                </input-container-component>
                            </div>
                            <div class="col-2 mb-2">
                                <input-container-component titulo="CNS" id="inputCns">
                                    <input type="text" class="form-control" id="inputCns" placeholder="Informe o Cns"
                                        v-model="busca.cns" @keyup="pesquisar()">
                                </input-container-component>
                            </div>
                            <div class="col-2 mb-2">
                                <input-container-component titulo="Centro de Saúde" id="inputCentSaude">
                                    <input type="text" class="form-control" id="inputCentSaude"
                                        placeholder="Informe o Centro de Saúde" v-model="busca.centro_saude"
                                        @keyup="pesquisar()">
                                </input-container-component>
                            </div>
                            <div class="col-2 mb-2">
                                <input-container-component titulo="Profissional Referência"
                                    id="pesquisaProfissionalReferencia">
                                    <select name="pesquisaProfissionalReferencia" class="form-control"
                                        id="pesquisaProfissionalReferencia" v-model="busca.prof_referencia"
                                        @change="pesquisar()">
                                        <option></option>
                                        <option v-for="lista in listaNomesProfissionais" :key="lista.id"
                                            :value="lista.id">
                                            {{ lista.nome }}
                                        </option>
                                    </select>
                                </input-container-component>
                            </div>
                            <div class="col-4" v-if="userJson.nivel_acesso != 'consulta'">
                                <button class="btn btn-danger float-end mb-2" style="margin: 10px; font-size: 10px;"
                                    data-bs-toggle="modal" data-bs-target="#modalFichaPdf">Gerar PDFs...</button>
                                    <a href="/exportar/base" class="btn btn-success float-end"
                                    style="margin: 10px; font-size: 10px;">Exportar XLSX</a>
                                    <a href="/raas-branco" class="btn btn-primary float-end" target="_blank"
                                    style="margin: 10px; font-size: 10px;">Raas</a>
                            </div>
                        </div>
                        <table-component :dados="pacientes.data" componentpai="pacientes" :editar="{
                            visivel: true,
                            dataToggle: 'modal',
                            dataTarget: '#modalFichaAtualizar'
                        }"
                        :pdf="true"
                        :titulos="{
                            id: { titulo: 'ID', tipo: 'texto' },
                            nome_usuario: { titulo: 'Nome', tipo: 'texto' },
                            cns: { titulo: 'CNS', tipo: 'texto' },
                            n_prontuario: { titulo: 'Número Prontuário', tipo: 'texto' },
                            centro_saude: { titulo: 'Centro de Saúde', tipo: 'texto' },
                            nome_profissional_referencia: { titulo: 'Profissional de Referência', tipo: 'texto' },
                            status: { titulo: 'Ativo / Inativo', tipo: 'texto' }
                        }" style="font-size: 11px;"></table-component>
                    </template>
                    <template v-slot:rodape>
                        <div class="row">
                            <div class="col-10">
                                <paginate-component>
                                    <li v-for="l, key in pacientes.links" :key="key"
                                        :class="l.active ? 'page-item active' : 'page-item'"><a class="page-link"
                                            v-html="l.label" @click="paginacao(l)"></a></li>
                                </paginate-component>
                            </div>
                        </div>
                    </template>
                </card-component>
            </div>
        </div>

        <modal-component id="modalFichaPdf" titulo="Pdf Pacientes" tipomodal="modal-sm">
            <template v-slot:alertas>
                <alert-component tipo="success" :detalhes="$store.state.transacao.mensagem"
                    titulo="Cadastro realizado com sucesso!"
                    v-if="$store.state.transacao.status == 'sucesso'"></alert-component>
                <alert-component tipo="danger" :detalhes="$store.state.transacao.mensagem" titulo="Erro ao cadastrar."
                    v-if="$store.state.transacao.status == 'erro'"></alert-component>
            </template>
            <template v-slot:conteudo>
                <pdf-geral-component :capsid="userJson.caps_id"
                    :listaprof="listaNomesProfissionais"></pdf-geral-component>
            </template>
            <template v-slot:rodape>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                    ref="fecharModal">Fechar</button>
            </template>
        </modal-component>

        <modal-component id="modalFichaAtualizar" titulo="Atualizar Paciente" tipomodal="modal-xl">
            <template v-slot:alertas>
                <div class="box" @mousedown="startDrag" :style="{ top: `${position.y}px`, left: `${position.x}px` }">
                    <div class="closedrag"
                        v-if="$store.state.transacao.status == 'sucesso' || $store.state.transacao.status == 'erro'">
                        <span @click="closeDrag">X</span>
                    </div>
                    <alert-component tipo="success" :detalhes="$store.state.transacao.mensagem"
                        titulo="Cadastro realizado com sucesso!"
                        v-if="$store.state.transacao.status == 'sucesso'"></alert-component>
                    <alert-component tipo="danger" :detalhes="$store.state.transacao.mensagem"
                        titulo="Erro ao cadastrar." v-if="$store.state.transacao.status == 'erro'"></alert-component>
                </div>
            </template>
            <template v-slot:conteudo>
                <div class="row">
                    <div>
                        <!-- <button type="button" @click="downloadPDF($store.state.item.id, 'pdf')"
                            class="btn btn-primary float-start" :disabled="loading">Baixar PDF</button>
                        <loader-pdf-component ref="loader" /> -->
                        <a :href="'https://printraas.candido.org.br/pdf/' + $store.state.item.id" target="_blank"
                            class="btn btn-danger float-start">PDF</a>
                        <span v-if="userJson.nivel_acesso != 'consulta'">
                            <button v-if="$store.state.item.status == 'Ativo'" type="button"
                                class="btn btn-danger float-end" @click="mudarStatus('Inativo')">Desativar</button>
                            <button v-else type="button" class="btn btn-primary float-end"
                                @click="mudarStatus('Ativo')">Ativar</button>
                        </span>
                    </div>
                </div>
                <div
                    :style="$store.state.item.status == 'Inativo' || userJson.nivel_acesso == 'consulta' ? 'pointer-events: none; mix-blend-mode: multiply' : ''">
                    <div class="row mb-4">
                        <div class="col">
                            <input-container-component titulo="Caps">
                                <input type="text" class="form-control" :value="capsJson.nome" disabled>
                            </input-container-component>
                        </div>
                        <div class="col">
                            <input-container-component titulo="Cnes">
                                <input type="text" class="form-control" :value="capsJson.cnes" disabled>
                            </input-container-component>
                        </div>
                        <div class="col">
                            <input-container-component titulo="Número do prontuário" id="numeroProntuario">
                                <input type="text" class="form-control" id="numeroProntuario"
                                    placeholder="Informe o número do prontuário"
                                    v-model="$store.state.item.n_prontuario">
                            </input-container-component>
                        </div>
                        <div class="col">
                            <input-container-component titulo="Mês/Ano Atendimento" id="mesAnoAtendimento">
                                <input type="text" class="form-control" id="mesAnoAtendimento"
                                    placeholder="Informe o mês/ano de antendimento"
                                    v-model="$store.state.item.mes_ano_atendimento">
                            </input-container-component>
                        </div>
                        <div class="col" style="align-content: center;">
                            <span v-if="userJson.nivel_acesso != 'consulta'">
                                <button v-if="$store.state.item.status == 'Ativo'" type="button"
                                    class="btn btn-primary float-end" style="font-size: 10px;"
                                    @click="mudarMesAtendimento()">Alterar todos os registros</button>
                            </span>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col">
                            <input-container-component titulo="Nome Usuario" id="editNome">
                                <input type="text" class="form-control" id="editNome"
                                    placeholder="Informe o Nome do Usuario" v-model="$store.state.item.nome_usuario">
                            </input-container-component>
                        </div>
                        <div class="col">
                            <input-container-component titulo="Cns" id="editCns">
                                <input type="text" class="form-control" id="editCns"
                                    placeholder="Informe o Cns do Usuario" v-model="$store.state.item.cns">
                            </input-container-component>
                        </div>
                        <div class="col">
                            <input-container-component titulo="Data Admissão Usuario" id="editDataAdmissao">
                                <input type="texti" class="form-control" id="editDataAdmissao"
                                    placeholder="Informe o mês/ano de antendimento"
                                    v-model="$store.state.item.admissao">
                            </input-container-component>
                        </div>
                        <div class="col">
                            <input-container-component titulo="Data nascimento" id="editDataNascimento">
                                <input type="text" class="form-control" id="editDataNascimento"
                                    placeholder="Informe o mês/ano de antendimento"
                                    v-model="$store.state.item.nascimento">
                            </input-container-component>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col">
                            <input-container-component titulo="Nome da mãe" id="editNomeMae">
                                <input type="text" class="form-control" id="editNomeMae"
                                    placeholder="Informe o Nome da Mãe" v-model="$store.state.item.nome_mae">
                            </input-container-component>
                        </div>
                        <div class="col">
                            <input-container-component titulo="Nome Responsavel" id="editResponsavel">
                                <input type="text" class="form-control" id="editResponsavel"
                                    placeholder="Informe o Nome do Responsavel" v-model="$store.state.item.responsavel">
                            </input-container-component>
                        </div>
                        <div class="col">
                            <input-container-component titulo="Raça / Cor" id="editRacaCor">
                                <input type="text" class="form-control" id="editRacaCor"
                                    placeholder="Informe a Raça / Cor" v-model="$store.state.item.raca_cor">
                            </input-container-component>
                        </div>
                        <div class="col">
                            <input-container-component titulo="Etnia Indigena" id="editEtniaIndigena">
                                <input type="text" class="form-control" id="editEtniaIndigena"
                                    placeholder="Informe Etinia Indigena" v-model="$store.state.item.etnia_indigena">
                            </input-container-component>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col">
                            <input-container-component titulo="Sexo" id="editSexo">
                                <select name="editSexo" class="form-control" id="editSexo"
                                    v-model="$store.state.item.genero">
                                    <option value="F">Feminino</option>
                                    <option value="M">Masculino</option>
                                </select>
                            </input-container-component>
                        </div>
                        <div class="col">
                            <input-container-component titulo="Telefone de Contato" id="editTelefoneContato">
                                <input type="text" class="form-control" id="editTelefoneContato"
                                    placeholder="Informe o Telefone de Contato" v-model="$store.state.item.telefone">
                            </input-container-component>
                        </div>
                        <div class="col">
                            <input-container-component titulo="Centro de Saúde" id="autocompletecentrosaude">
                                <auto-complete-component titulo="Informe Centro de Saúde"
                                    id_input="autocompletecentrosaude" id_div="divcentrosaude" valuemodel="centrosaude"
                                    v-model="$store.state.item.centro_saude"></auto-complete-component>
                            </input-container-component>
                        </div>
                        <div class="col">
                            <input-container-component titulo="Endereço" id="editEndereco">
                                <input type="text" class="form-control" id="editEndereco"
                                    placeholder="Informe o Nome Endereço" v-model="$store.state.item.endereco">
                            </input-container-component>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col">
                            <input-container-component titulo="Municipio de Residência" id="editMunResidencia">
                                <input type="text" class="form-control" id="editMunResidencia"
                                    placeholder="Informe o Municipio de residencia" v-model="$store.state.item.mun_res">
                            </input-container-component>
                        </div>
                        <div class="col">
                            <input-container-component titulo="Cep" id="editCep">
                                <input type="text" class="form-control" id="editCep" placeholder="Informe o Cep"
                                    v-model="$store.state.item.cep">
                            </input-container-component>
                        </div>
                        <div class="col">
                            <input-container-component titulo="Nacionalidade" id="editNacionalidade">
                                <input type="text" class="form-control" id="editNacionalidade"
                                    placeholder="Informe a Nacionalidade" v-model="$store.state.item.nacionalidade">
                            </input-container-component>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col">
                            <input-container-component titulo="Usuário de Álcool e Outras Drogas" id="editAlcoolDrogas">
                                <br>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="editSim"
                                        v-model="$store.state.item.alcool_drogas">
                                    <label class="form-check-label" for="editSim">Sim</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="editNao"
                                        v-model="$store.state.item.alcool_drogas_n">
                                    <label class="form-check-label" for="editNao">Não</label>
                                </div>
                                <p>se Sim:</p>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="editAlcool"
                                        v-model="$store.state.item.alcool">
                                    <label class="form-check-label" for="editAlcool">Alcool</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="editCrack"
                                        v-model="$store.state.item.crack">
                                    <label class="form-check-label" for="editCrack">Crack</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="editCannabis"
                                        v-model="$store.state.item.cannabis">
                                    <label class="form-check-label" for="editCannabis">Cannabis</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="editCocaina"
                                        v-model="$store.state.item.cocaina">
                                    <label class="form-check-label" for="editCocaina">Cocaina</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="editOutras"
                                        v-model="$store.state.item.outras_drogas" disabled>
                                    <label class="form-check-label" for="editOutras">Outras</label>
                                </div>
                            </input-container-component>
                        </div>
                        <div class="col">
                            <input-container-component titulo="Outras Drogas" id="editOutrasDrogas">
                                <input type="text" class="form-control" id="editOutrasDrogas"
                                    placeholder="Informe as Outras Drogas" v-model="$store.state.item.outras_drogas">
                            </input-container-component>
                        </div>
                        <div class="col">
                            <input-container-component titulo="Situação de Rua" id="editSituacaoRua">
                                <select name="editSituacaoRua" class="form-control" id="editSituacaoRua"
                                    v-model="$store.state.item.situacao_rua">
                                    <option value="S">Sim</option>
                                    <option value="N">Não</option>
                                </select>
                            </input-container-component>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col">
                            <input-container-component titulo="Encaminhamento" id="editOrigem">
                                <br>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="editContAcompCaps"
                                        v-model="$store.state.item.encaminhamento_continuidade_caps">
                                    <label class="form-check-label" for="editContAcompCaps">Continuidade do Acomp. em
                                        CAPS</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="editContAtenBasic"
                                        v-model="$store.state.item.encaminhamento_continuidade_basica">
                                    <label class="form-check-label" for="editContAtenBasic">Continuidade na Atenção
                                        Básica</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="editAlta"
                                        v-model="$store.state.item.encaminhamento_alta">
                                    <label class="form-check-label" for="editAlta">Alta</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="editObito"
                                        v-model="$store.state.item.encaminhamento_obito">
                                    <label class="form-check-label" for="editObito">Óbito</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="editAbandono"
                                        v-model="$store.state.item.encaminhamento_abandono">
                                    <label class="form-check-label" for="editAbandono">Abandono</label>
                                </div>
                            </input-container-component>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col">
                            <input-container-component titulo="Origem" id="editEncaminhamento">
                                <br>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="editDemandaEspo"
                                        v-model="$store.state.item.demanda_espontanea">
                                    <label class="form-check-label" for="editDemandaEspo">Demanda Espntânea</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="editUbs"
                                        v-model="$store.state.item.ubs">
                                    <label class="form-check-label" for="editUbs">UBS</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="editServUE"
                                        v-model="$store.state.item.serv_u_e">
                                    <label class="form-check-label" for="editServUE">Serv. U/E</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="editOutroCaps"
                                        v-model="$store.state.item.outro_caps">
                                    <label class="form-check-label" for="editOutroCaps">Outro Caps</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="editHospGeral"
                                        v-model="$store.state.item.hosp_geral">
                                    <label class="form-check-label" for="editHospGeral">Hospital Geral</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="editHospGeral"
                                        v-model="$store.state.item.hosp_psiquiatra">
                                    <label class="form-check-label" for="editHospGeral">Hospital Psiquiátrico</label>
                                </div>
                            </input-container-component>
                        </div>
                        <div class="col">
                            <input-container-component titulo="Origem Outros" id="editOrigemOutros">
                                <input type="text" class="form-control" id="editOrigemOutros"
                                    placeholder="Informe a Origem Outros" v-model="$store.state.item.origem_outros">
                            </input-container-component>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col">
                            <input-container-component titulo="Existe Estratégia de Saúde da Familia:"
                                id="editEstrategiaSaudeFamilia">
                                <select name="editEstrategiaSaudeFamilia" class="form-control"
                                    id="editEstrategiaSaudeFamilia" v-model="$store.state.item.estrategia_sau_familiar">
                                    <option value=""></option>
                                    <option value="S">Sim</option>
                                    <option value="N">Não</option>
                                </select>
                            </input-container-component>
                        </div>
                        <div class="col">
                            <input-container-component titulo="Cnes CTS" id="editCnesCts">
                                <input type="text" class="form-control" id="editCnesCts"
                                    placeholder="Informe o Municipio de residencia"
                                    v-model="$store.state.item.cnes_cts">
                            </input-container-component>
                        </div>
                        <div class="col">
                            <input-container-component titulo="Profissional Referência" id="editProfissionalReferencia">
                                <select name="editProfissionalReferencia" class="form-control"
                                    id="editProfissionalReferencia" v-model="$store.state.item.prof_referencia">
                                    <option value=""></option>
                                    <option v-for="lista in listaNomesProfissionais" :key="lista.id" :value="lista.id">
                                        {{ lista.nome }}
                                    </option>
                                </select>
                            </input-container-component>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col">
                            <input-container-component titulo="CID10 Principal" id="autocomplete_princ">
                                <auto-complete-component titulo="Informe CID 10 Principal" id_input="autocomplete_princ"
                                    id_div="divprincp" valuemodel="cid10princ"
                                    v-model="$store.state.item.cid10_principal"></auto-complete-component>
                            </input-container-component>
                        </div>
                        <div class="col">
                            <input-container-component titulo="CID 10 Causas Associadas" id="autocomplete_assoc">
                                <auto-complete-component titulo="Informe CID 10 Causas Associadas"
                                    id_input="autocomplete_assoc" id_div="divcausassoc" valuemodel="cid10assoc"
                                    v-model="$store.state.item.cid10_causas_associadas"></auto-complete-component>
                            </input-container-component>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:rodape>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                    ref="fecharModal">Fechar</button>
                <button type="button" class="btn btn-primary" @click="atualizarFicha()" v-if="userJson.nivel_acesso != 'consulta'">Atualizar</button>
            </template>
        </modal-component>
        <!-- ###################################################################################################################
###################################################################################################################
###################################################################################################################
################################################################################################################### -->
        <modal-component id="modalFichaRemover" titulo="Remover Paciente" tipomodal="modal-xl">
            <template v-slot:alertas>
                <div class="box" @mousedown="startDrag" :style="{ top: `${position.y}px`, left: `${position.x}px` }">
                    <alert-component tipo="success" :detalhes="$store.state.transacao.mensagem"
                        titulo="Cadastro realizado com sucesso!"
                        v-if="$store.state.transacao.status == 'sucesso'"></alert-component>
                    <alert-component tipo="danger" :detalhes="$store.state.transacao.mensagem"
                        titulo="Erro ao cadastrar." v-if="$store.state.transacao.status == 'erro'"></alert-component>
                </div>
            </template>
            <template v-slot:conteudo>
                <div style="pointer-events: none; mix-blend-mode: multiply">
                    <div class="row mb-4">
                        <div class="col">
                            <input-container-component titulo="Caps">
                                <input type="text" class="form-control" :value="capsJson.nome" disabled>
                            </input-container-component>
                        </div>
                        <div class="col">
                            <input-container-component titulo="Cnes">
                                <input type="text" class="form-control" :value="capsJson.cnes" disabled>
                            </input-container-component>
                        </div>
                        <div class="col">
                            <input-container-component titulo="Número do prontuário" id="numeroProntuario">
                                <input type="text" class="form-control" id="numeroProntuario"
                                    placeholder="Informe o número do prontuário"
                                    v-model="$store.state.item.n_prontuario">
                            </input-container-component>
                        </div>
                        <div class="col">
                            <input-container-component titulo="Mês/Ano Atendimento" id="mesAnoAtendimento">
                                <input type="text" class="form-control" id="mesAnoAtendimento"
                                    placeholder="Informe o mês/ano de antendimento"
                                    v-model="$store.state.item.mes_ano_atendimento">
                            </input-container-component>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col">
                            <input-container-component titulo="Nome Usuario" id="editNome">
                                <input type="text" class="form-control" id="editNome"
                                    placeholder="Informe o Nome do Usuario" v-model="$store.state.item.nome_usuario">
                            </input-container-component>
                        </div>
                        <div class="col">
                            <input-container-component titulo="Cns" id="editCns">
                                <input type="text" class="form-control" id="editCns"
                                    placeholder="Informe o Cns do Usuario" v-model="$store.state.item.cns">
                            </input-container-component>
                        </div>
                        <div class="col">
                            <input-container-component titulo="Data Admissão Usuario" id="editDataAdmissao">
                                <input type="date" class="form-control" id="editDataAdmissao"
                                    v-model="$store.state.item.admissao">
                            </input-container-component>
                        </div>
                        <div class="col">
                            <input-container-component titulo="Data nascimento" id="editDataNascimento">
                                <input type="date" class="form-control" id="editDataNascimento"
                                    v-model="$store.state.item.nascimento">
                            </input-container-component>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col">
                            <input-container-component titulo="Nome da mãe" id="editNomeMae">
                                <input type="text" class="form-control" id="editNomeMae"
                                    placeholder="Informe o Nome da Mãe" v-model="$store.state.item.nome_mae">
                            </input-container-component>
                        </div>
                        <div class="col">
                            <input-container-component titulo="Nome Responsavel" id="editResponsavel">
                                <input type="text" class="form-control" id="editResponsavel"
                                    placeholder="Informe o Nome do Responsavel" v-model="$store.state.item.responsavel">
                            </input-container-component>
                        </div>
                        <div class="col">
                            <input-container-component titulo="Raça / Cor" id="editRacaCor">
                                <input type="text" class="form-control" id="editRacaCor"
                                    placeholder="Informe a Raça / Cor" v-model="$store.state.item.raca_cor">
                            </input-container-component>
                        </div>
                        <div class="col">
                            <input-container-component titulo="Etnia Indigena" id="editEtniaIndigena">
                                <input type="text" class="form-control" id="editEtniaIndigena"
                                    placeholder="Informe Etinia Indigena" v-model="$store.state.item.etnia_indigena">
                            </input-container-component>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col">
                            <input-container-component titulo="Sexo" id="editSexo">
                                <select name="editSexo" class="form-control" id="editSexo"
                                    v-model="$store.state.item.genero">
                                    <option value="F">Feminino</option>
                                    <option value="M">Masculino</option>
                                </select>
                            </input-container-component>
                        </div>
                        <div class="col">
                            <input-container-component titulo="Telefone de Contato" id="editTelefoneContato">
                                <input type="text" class="form-control" id="editTelefoneContato"
                                    placeholder="Informe o Telefone de Contato" v-model="$store.state.item.telefone">
                            </input-container-component>
                        </div>
                        <div class="col">
                            <input-container-component titulo="Centro de Saúde" id="editCentroSaude">
                                <input type="text" class="form-control" id="editCentroSaude"
                                    placeholder="Informe o Centro de Saúde" v-model="$store.state.item.centro_saude">
                            </input-container-component>
                        </div>
                        <div class="col">
                            <input-container-component titulo="Endereço" id="editEndereco">
                                <input type="text" class="form-control" id="editEndereco"
                                    placeholder="Informe o Nome Endereço" v-model="$store.state.item.endereco">
                            </input-container-component>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col">
                            <input-container-component titulo="Municipio de Residência" id="editMunResidencia">
                                <input type="text" class="form-control" id="editMunResidencia"
                                    placeholder="Informe o Municipio de residencia" v-model="$store.state.item.mun_res">
                            </input-container-component>
                        </div>
                        <div class="col">
                            <input-container-component titulo="Cep" id="editCep">
                                <input type="text" class="form-control" id="editCep" placeholder="Informe o Cep"
                                    v-model="$store.state.item.cep">
                            </input-container-component>
                        </div>
                        <div class="col">
                            <input-container-component titulo="Nacionalidade" id="editNacionalidade">
                                <input type="text" class="form-control" id="editNacionalidade"
                                    placeholder="Informe a Nacionalidade" v-model="$store.state.item.nacionalidade">
                            </input-container-component>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col">
                            <input-container-component titulo="Usuário de Álcool e Outras Drogas" id="editEndereco">
                                <br>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="editNao"
                                        v-model="$store.state.item.alcool_drogas">
                                    <label class="form-check-label" for="editNao">Sim</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="editNao"
                                        v-model="$store.state.item.alcool_drogas_n">
                                    <label class="form-check-label" for="editNao">Não se Sim:</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="editAlcool"
                                        v-model="$store.state.item.alcool">
                                    <label class="form-check-label" for="editAlcool">Alcool</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="editCrack"
                                        v-model="$store.state.item.crack">
                                    <label class="form-check-label" for="editCrack">Crack</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="editCannabis"
                                        v-model="$store.state.item.cannabis">
                                    <label class="form-check-label" for="editCannabis">Cannabis</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="editCocaina"
                                        v-model="$store.state.item.cocaina">
                                    <label class="form-check-label" for="editCocaina">Cocaina</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="editOutras"
                                        v-model="$store.state.item.outras_drogas">
                                    <label class="form-check-label" for="editOutras">Outras</label>
                                </div>
                            </input-container-component>
                        </div>
                        <div class="col">
                            <input-container-component titulo="Outras Drogas" id="editOutrasDrogas">
                                <input type="text" class="form-control" id="editOutrasDrogas"
                                    placeholder="Informe as Outras Drogas" v-model="$store.state.item.outras_drogas">
                            </input-container-component>
                        </div>
                        <div class="col">
                            <input-container-component titulo="Situação de Rua" id="editSituacaoRua">
                                <select name="editSituacaoRua" class="form-control" id="editSituacaoRua"
                                    v-model="$store.state.item.situacao_rua">
                                    <option value="S">Sim</option>
                                    <option value="N">Não</option>
                                </select>
                            </input-container-component>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col">
                            <input-container-component titulo="Encaminhamento" id="editOrigem">
                                <br>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="editContAcompCaps"
                                        v-model="$store.state.item.encaminhamento_continuidade_caps">
                                    <label class="form-check-label" for="editContAcompCaps">Continuidade do Acomp. em
                                        CAPS</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="editContAtenBasic"
                                        v-model="$store.state.item.encaminhamento_continuidade_basica">
                                    <label class="form-check-label" for="editContAtenBasic">Continuidade na Atenção
                                        Básica</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="editAlta"
                                        v-model="$store.state.item.encaminhamento_alta">
                                    <label class="form-check-label" for="editAlta">Alta</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="editObito"
                                        v-model="$store.state.item.encaminhamento_obito">
                                    <label class="form-check-label" for="editObito">Óbito</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="editAbandono"
                                        v-model="$store.state.item.encaminhamento_abandono">
                                    <label class="form-check-label" for="editAbandono">Abandono</label>
                                </div>
                            </input-container-component>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col">
                            <input-container-component titulo="Origem" id="editEncaminhamento">
                                <br>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="editDemandaEspo"
                                        v-model="$store.state.item.demanda_espontanea">
                                    <label class="form-check-label" for="editDemandaEspo">Demanda Espntânea</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="editUbs"
                                        v-model="$store.state.item.outras_drogas">
                                    <label class="form-check-label" for="editUbs">UBS</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="editServUE"
                                        v-model="$store.state.item.serv_u_e">
                                    <label class="form-check-label" for="editServUE">Serv. U/E</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="editOutroCaps"
                                        v-model="$store.state.item.outro_caps">
                                    <label class="form-check-label" for="editOutroCaps">Outro Caps</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="editHospGeral"
                                        v-model="$store.state.item.hosp_geral">
                                    <label class="form-check-label" for="editHospGeral">Hospital Geral</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="editHospGeral"
                                        v-model="$store.state.item.hosp_psiquiatra">
                                    <label class="form-check-label" for="editHospGeral">Hospital Psiquiátrico</label>
                                </div>
                            </input-container-component>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col">
                            <input-container-component titulo="Existe Estratégia de Saúde da Familia:"
                                id="editEstrategiaSaudeFamilia">
                                <select name="editEstrategiaSaudeFamilia" class="form-control"
                                    id="editEstrategiaSaudeFamilia" v-model="$store.state.item.estrategia_sau_familiar">
                                    <option value=""></option>
                                    <option value="S">Sim</option>
                                    <option value="N">Não</option>
                                </select>
                            </input-container-component>
                        </div>
                        <div class="col">
                            <input-container-component titulo="Cnes CTS" id="editCnesCts">
                                <input type="text" class="form-control" id="editCnesCts"
                                    placeholder="Informe o Municipio de residencia"
                                    v-model="$store.state.item.cnes_cts">
                            </input-container-component>
                        </div>
                        <div class="col">
                            <input-container-component titulo="Profissional Referência" id="editProfissionalReferencia">
                                <select name="editProfissionalReferencia" class="form-control"
                                    id="editProfissionalReferencia" v-model="$store.state.item.prof_referencia">
                                    <option value=""></option>
                                    <option v-for="lista in listaNomesProfissionais" :key="lista.id" :value="lista.id">
                                        {{ lista.nome }}
                                    </option>
                                </select>
                            </input-container-component>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col">
                            <input-container-component titulo="CID10 Principal" id="editCid10Principal">
                                <input type="text" class="form-control" id="editCid10Principal"
                                    placeholder="Informe o Cid10 Principal" v-model="$store.state.item.cid10_principal">
                            </input-container-component>
                        </div>
                        <div class="col">
                            <input-container-component titulo="CID 10 Causas Associadas" id="editCid10CausAssoc">
                                <input type="text" class="form-control" id="editCid10CausAssoc"
                                    placeholder="Informe o CID 10 Causas Associadas"
                                    v-model="$store.state.item.cid10_causas_associadas">
                            </input-container-component>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:rodape>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                <button type="button" class="btn btn-danger" @click="removerFicha()"
                    v-if="$store.state.transacao.status != 'sucesso'">Remover</button>
            </template>
        </modal-component>
    </div>
</template>

<script>
export default {
    props: ['user', 'caps_props'],
    data() {
        return {
            urlBase: 'https://printraas.candido.org.br/api/v1/ficha',
            urlPaginacao: '',
            urlFiltro: '',
            listaNomesProfissionais: [],
            pacientes: { data: [] },
            busca: { nome_usuario: '', cns: '', centro_saude: '', prof_referencia: '' },
            listaCaps: {},
            links: {},
            isDragging: false,
            position: { x: 230, y: 20 },      // Posição inicial da div
            offset: { x: 230, y: 20 },
            loading: false
        }
    },
    methods: {
        async downloadPDF(id, tipopdf) {
            // Iniciar o carregamento
            this.loading = true;
            if (tipopdf == 'pdf') {
                this.$refs.loader.startLoading();
            } else {
                this.$refs.loadergeral.startLoading();
            }
            let response = ''

            try {
                // Realizar a requisição para o backend para gerar o PDF
                if (tipopdf == 'pdf') {
                    response = await axios.get('/pdf/' + id, { responseType: 'blob' });
                } else {
                    response = await axios.get('/pdfgeral/' + id, { responseType: 'blob' });
                }

                // Criar um link temporário para o download
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                if (tipopdf === 'pdf') {
                    link.setAttribute('download', this.$store.state.item.nome_usuario + '.pdf');
                } else {
                    link.setAttribute('download', this.$store.state.item.nome_usuario + '.zip');
                }
                document.body.appendChild(link);
                link.click();

                // Limpar o link
                document.body.removeChild(link);
            } catch (error) {
                console.error('Erro ao gerar PDF:', error);
            } finally {
                // Parar o carregamento
                this.loading = false;
                if (tipopdf == 'pdf') {
                    this.$refs.loader.stopLoading();
                } else {
                    this.$refs.loadergeral.stopLoading();
                }
            }
        },
        mudarStatus(valor) {
            let confirmacao = confirm('Confirmar remoção do registro!')
            if (!confirmacao) return false
            let formData = new FormData()
            formData.append('_method', 'patch')
            formData.append('status', valor)
            formData.append('caps_id', this.userJson.caps_id)

            let config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': this.token
                }
            }

            axios.post(this.urlBase + '/' + this.$store.state.item.id, formData, config)
                .then(response => {
                    this.carregarLista()
                    this.$store.state.transacao.status = 'sucesso'
                    this.$store.state.transacao.mensagem = response.data
                    this.$refs.fecharModal.click()
                })
                .catch(errors => {
                    this.$store.state.transacao.status = 'erro'
                    this.$store.state.transacao.mensagem = {
                        mensagem: errors.response.data.mensagem,
                        dados: errors.response.data.errors
                    }
                })
        },
        mudarMesAtendimento() {
            let formData = new FormData()
            formData.append('mes_ano_atendimento', this.$store.state.item.mes_ano_atendimento)
            formData.append('caps_id', this.userJson.caps_id)

            let config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': this.token
                }
            }

            axios.post('https://printraas.candido.org.br/api/v1/mudar_mes_atendimento', formData, config)
                .then(response => {
                    this.carregarLista()
                    this.$store.state.transacao.status = 'sucesso'
                    this.$store.state.transacao.mensagem = response.data
                })
                .catch(errors => {
                    console.log(errors.response.data)
                    this.$store.state.transacao.status = 'erro'
                    this.$store.state.transacao.mensagem = {
                        mensagem: errors.response.data.mensagem,
                        dados: errors.response.data.errors
                    }
                })
        },
        atualizarFicha() {
            console.log(this.$store.state.item)
            let formData = new FormData()
            formData.append('_method', 'patch')
            formData.append('n_prontuario', this.$store.state.item.n_prontuario)
            formData.append('cnes', this.capsJson.cnes)
            formData.append('cnes_cts', this.$store.state.item.cnes_cts)
            formData.append('caps', this.capsJson.nome)
            formData.append('mes_ano_atendimento', this.$store.state.item.mes_ano_atendimento)
            formData.append('cns', this.$store.state.item.cns)
            formData.append('nome_usuario', this.$store.state.item.nome_usuario)
            formData.append('admissao', this.$store.state.item.admissao)
            formData.append('nascimento', this.$store.state.item.nascimento)
            formData.append('nome_mae', this.$store.state.item.nome_mae)
            formData.append('responsavel', this.$store.state.item.responsavel)
            formData.append('raca_cor', this.$store.state.item.raca_cor)
            formData.append('etnia_indigena', this.$store.state.item.etnia_indigena)
            formData.append('genero', this.$store.state.item.genero)
            formData.append('centro_saude', this.$store.state.item.centro_saude)
            formData.append('telefone', this.$store.state.item.telefone)
            formData.append('endereco', this.$store.state.item.endereco)
            formData.append('mun_res', this.$store.state.item.mun_res)
            formData.append('cep', this.$store.state.item.cep)
            formData.append('nacionalidade', this.$store.state.item.nacionalidade)
            formData.append('situacao_rua', this.$store.state.item.situacao_rua)
            formData.append('origem_outros', this.$store.state.item.origem_outros == false ? '' : this.$store.state.item.origem_outros)
            formData.append('alcool_drogas', this.$store.state.item.alcool_drogas == false ? '' : this.$store.state.item.alcool_drogas)
            formData.append('alcool_drogas_n', this.$store.state.item.alcool_drogas_n == false ? '' : this.$store.state.item.alcool_drogas_n)
            formData.append('alcool', this.$store.state.item.alcool == false ? '' : this.$store.state.item.alcool)
            formData.append('crack', this.$store.state.item.crack == false ? '' : this.$store.state.item.crack)
            formData.append('cocaina', this.$store.state.item.cocaina == false ? '' : this.$store.state.item.cocaina)
            formData.append('cannabis', this.$store.state.item.cannabis == false ? '' : this.$store.state.item.cannabis)
            formData.append('outras_drogas', this.$store.state.item.outras_drogas == false ? '' : this.$store.state.item.outras_drogas)
            formData.append('demanda_espontanea', this.$store.state.item.demanda_espontanea == false ? '' : this.$store.state.item.demanda_espontanea)
            formData.append('ubs', this.$store.state.item.ubs == false ? '' : this.$store.state.item.ubs)
            formData.append('serv_u_e', this.$store.state.item.serv_u_e == false ? '' : this.$store.state.item.serv_u_e)
            formData.append('outro_caps', this.$store.state.item.outro_caps == false ? '' : this.$store.state.item.outro_caps)
            formData.append('hosp_geral', this.$store.state.item.hosp_geral == false ? '' : this.$store.state.item.hosp_geral)
            formData.append('hosp_psiquiatra', this.$store.state.item.hosp_psiquiatra == false ? '' : this.$store.state.item.hosp_psiquiatra)
            formData.append('cid10_principal', this.$store.state.item.cid10_principal)
            formData.append('cid10_causas_associadas', this.$store.state.item.cid10_causas_associadas)
            formData.append('estrategia_sau_familiar', this.$store.state.item.estrategia_sau_familiar)
            formData.append('encaminhamento_continuidade_caps', this.$store.state.item.encaminhamento_continuidade_caps == false ? '' : this.$store.state.item.encaminhamento_continuidade_caps)
            formData.append('encaminhamento_continuidade_basica', this.$store.state.item.encaminhamento_continuidade_basica == false ? '' : this.$store.state.item.encaminhamento_continuidade_basica)
            formData.append('encaminhamento_alta', this.$store.state.item.encaminhamento_alta == false ? '' : this.$store.state.item.encaminhamento_alta)
            formData.append('encaminhamento_obito', this.$store.state.item.encaminhamento_obito == false ? '' : this.$store.state.item.encaminhamento_obito)
            formData.append('encaminhamento_abandono', this.$store.state.item.encaminhamento_abandono == false ? '' : this.$store.state.item.encaminhamento_abandono)
            formData.append('prof_referencia', this.$store.state.item.prof_referencia)
            formData.append('status_paciente', this.$store.state.item.status_paciente)
            formData.append('caps_id', this.userJson.caps_id)

            let config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': this.token
                }
            }

            axios.post(this.urlBase + '/' + this.$store.state.item.id, formData, config)
                .then(response => {
                    this.carregarLista()
                    this.$store.state.transacao.status = 'sucesso'
                    this.$store.state.transacao.mensagem = response.data
                })
                .catch(errors => {
                    console.log(errors.response)
                    this.$store.state.transacao.status = 'erro'
                    this.$store.state.transacao.mensagem = {
                        mensagem: errors.response.data.mensagem,
                        dados: errors.response.data.errors
                    }
                })
        },
        removerFicha() {
            let confirmacao = confirm('Confirmar remoção do registro!')

            if (!confirmacao) return false

            let formData = new FormData()
            formData.append('_method', 'delete')

            let config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': this.token
                }
            }

            axios.post(this.urlBase + '/' + this.$store.state.item.id, formData, config)
                .then(response => {
                    this.carregarLista()
                    this.$store.state.transacao.status = 'sucesso'
                    this.$store.state.transacao.mensagem = response.data
                })
                .catch(errors => {
                    this.$store.state.transacao.status = 'erro'
                    this.$store.state.transacao.mensagem = {
                        mensagem: errors.response.data.mensagem,
                        dados: errors.response.data.errors
                    }
                })
        },
        pesquisar() {
            let filtro = ''
            for (let chave in this.busca) {
                if (this.busca[chave]) {
                    if (filtro != '') {
                        filtro += ';'
                    }

                    filtro += chave + ':like:' + this.busca[chave] + '%'
                }
            }
            if (filtro != '') {
                this.urlPaginacao = 'page=1'
                this.urlFiltro = ';' + filtro
                // '&filtro=' +
            } else {
                this.urlFiltro = ''
            }
            this.carregarLista()
        },
        paginacao(l) {
            if (l.url) {
                this.urlPaginacao = l.url.split('?')[1]
                this.carregarLista()
            }
        },
        carregarLista() {
            let url = this.urlBase + '?' + this.urlPaginacao + '&filtro=caps_id:=:' + this.userJson.caps_id + this.urlFiltro
            let config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': this.token
                }
            }
            axios.get(url, config)
                .then(response => {
                    this.pacientes = response.data 
                    this.carregarListaComFiltro(this.pacientes.data)
                    this.quantidadeInativos()
                })
                .catch(error => {
                    console.error("Erro na requisição:", error);
                });

        },
        carregarListaComFiltro(obj) {
            obj.map(paciente => {
                const profissional = this.listaNomesProfissionais.find(prof => prof.id === parseInt(paciente.prof_referencia) && prof.caps_id === paciente.caps_id)
                if (profissional) {
                    paciente.nome_profissional_referencia = profissional.nome
                } else {
                    paciente.nome_profissional_referencia = ''
                }
                return paciente
            })
        },
        carregaListaCaps() {
            let url = 'https://printraas.candido.org.br/api/v1/caps?apenas_nomes=id,nome'

            let config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': this.token
                }
            }

            axios.get(url, config)
                .then(response => {
                    this.listaCaps = response.data
                })
                .catch(error => {
                    console.error("Erro na requisição:", error);
                });
        },
        quantidadeInativos() {
            let url = 'https://printraas.candido.org.br/quantidade-inativos/' + this.userJson.caps_id
            
            let config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': this.token
                }
            }
            axios.get(url, config)
                .then(response => {
                    this.$store.state.indicativos.inativos = response.data.quantidade
                })
                .catch(error => {
                    console.error("Erro na requisição:", error);
                });
        },
        startDrag(event) {
            // Define o movimento como ativo
            this.isDragging = true;

            // Calcula a posição inicial do cursor dentro da div
            this.offset.x = event.clientX - this.position.x;
            this.offset.y = event.clientY - this.position.y;

            // Adiciona listeners de movimento e soltura
            document.addEventListener('mousemove', this.drag);
            document.addEventListener('mouseup', this.stopDrag);
        },
        drag(event) {
            if (this.isDragging) {
                // Atualiza a posição da div baseada na posição do cursor
                this.position.x = event.clientX - this.offset.x;
                this.position.y = event.clientY - this.offset.y;
            }
        },
        stopDrag() {
            // Desativa o movimento e remove os listeners
            this.isDragging = false;
            document.removeEventListener('mousemove', this.drag);
            document.removeEventListener('mouseup', this.stopDrag);
        },
        listaProfissionais() {
            let url = 'https://printraas.candido.org.br/api/v1/profissional?apenas_nomes=id,nome,cns_profissional,caps_id' + '&filtro=caps_id:=:' + this.userJson.caps_id

            let config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': this.token
                }
            }

            axios.get(url, config)
                .then(response => {
                    this.listaNomesProfissionais = response.data
                })
                .catch(error => {
                    console.error("Erro na requisição:", error);
                });
        },
        closeDrag() {
            this.$store.state.transacao.status = ''
        }
    },
    mounted() {
        this.listaProfissionais()
        this.carregaListaCaps()
        this.carregarLista()
        this.quantidadeInativos()
    },
    computed: {
        token() {
            let token = 'Bearer ' + document.cookie.split(';').find(indice => {
                return indice.includes('token=')
            }).split('=')[1]
            return token
        },

        userJson() {
            return JSON.parse(this.user);
        },

        capsJson() {
            return JSON.parse(this.caps_props);
        },
    },
}
</script>
<style scoped>
.box {
    position: fixed;
    /* Para permitir o posicionamento baseado em top/left */
    cursor: move;
    /* Ícone de cursor de movimento */
    /* Adicione outros estilos aqui, como tamanho e cor */
    width: auto;
    z-index: 1;
    font-size: 12px;
    font-weight: bolder;
    opacity: 0.9;
}

.closedrag {
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: end;
    margin: 4px -10px;
    z-index: 1000;
}

.closedrag>span {
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
}
</style>
