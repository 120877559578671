<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-6"> 
                <div class="card">
                    <div class="card-header">Bem-vindo</div>

                    <div class="card-body">
                        <alert-component tipo="success" :detalhes="transacaoDetalhes"
                            titulo="Cadastro realizado com sucesso!"
                            v-if="transacaoStatus == 'sucesso'"></alert-component>
                        <alert-component tipo="danger" :detalhes="transacaoDetalhes" titulo="Erro ao cadastrar."
                            v-if="transacaoStatus == 'erro'"></alert-component>
                        <div class="form-group">
                            <input-container-component titulo="Nome Usuario" id="atualizarNome">
                                <input type="text" class="form-control" id="atualizarNome"
                                    placeholder="Informe o Nome do Usuario" v-model="usuario.name">
                            </input-container-component>
                        </div>
                        <div class="form-group">
                            <input-container-component titulo="Email Usuario" id="atualizarEmail">
                                <input type="email" class="form-control" id="atualizarEmail" :disabled="usuario.nivel_acesso !== 'adm'"
                                    placeholder="Informe o Email do Usuario" v-model="usuario.email">
                            </input-container-component>
                        </div>
                        <div class="form-group">
                            <input-container-component titulo="Senha Usuario" id="atualizarSenha">
                                <input type="password" class="form-control" id="atualizarSenha"
                                    placeholder="Informe a senha do Usuario" v-model="usuario.password">
                            </input-container-component>
                        </div>
                    </div>
                    <div class="card-footer">
                        <button type="submit" class="btn btn-primary btn-sm float-end" @click="salvar()">
                            salvar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['user'],
    data() {
        return {
            urlBase: 'https://printraas.candido.org.br/api/v1/user',
            usuario: {},
            listaCaps: {},
            transacaoStatus: '',
            transacaoDetalhes: {},
        }
    },
    methods: {
        resetTransacao() {
            setTimeout(() => {
                this.transacaoStatus = ''
                this.transacaoDetalhes = {} 
            }, 4000)
        },
        salvar() {
            let formData = new FormData();
            formData.append("_method", "patch");
            formData.append("name", this.usuario.name);
            formData.append("email", this.usuario.email);
            formData.append("nivel_acesso", this.usuario.nivel_acesso);
            formData.append("nivel_acesso", this.usuario.nivel_acesso);
            if (this.usuario.password) {
                formData.append("caps_id", this.usuario.caps_id);
            }

            let config = {
                headers: {
                    Accept: "application/json",
                    Authorization: this.token,
                },
            };

            axios.post(this.urlBase +'/'+ this.usuario.id, formData, config)
                .then(response => {
                    this.transacaoStatus = 'sucesso'
                    this.transacaoDetalhes = {
                        mensagem: 'Registro atualizado!'
                    }
                    this.resetTransacao()
                })
                .catch(errors => {
                    console.log(errors.response)
                    this.transacaoStatus = 'erro'
                    this.transacaoDetalhes = {
                        mensagem: errors.response.data.mensagem,
                        dados: errors.response.data.errors
                    }
                    this.resetTransacao()
                })
        },
        carregaListaCaps() {
            let url = 'https://printraas.candido.org.br/api/v1/caps?apenas_nomes=id,nome'

            let config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': this.token
                }
            }

            axios.get(url, config)
                .then(response => {
                    this.listaCaps = response.data
                })
                .catch(error => {
                    console.error("Erro na requisição:", error);
                });
        },
    },
    mounted() {
        this.usuario = this.userJson
        this.carregaListaCaps()
    },
    computed: {
        token() {
            let token = 'Bearer ' + document.cookie.split(';').find(indice => {
                return indice.includes('token=')
            }).split('=')[1]
            return token
        },

        userJson() {
            return JSON.parse(this.user);
        },
    },
}
</script>
