<template>
    <div v-if="loading" class="loader-container">
      <div class="loader"></div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        loading: false
      };
    },
    methods: {
      startLoading() {
        this.loading = true;
      },
      stopLoading() {
        this.loading = false;
      }
    }
  };
  </script>
  
  <style scoped>
  .loader-container {
    display: inline-block; /* Faz o loader ficar ao lado do botão */
    margin-left: 10px; /* Dá um pequeno espaço entre o botão e o loader */
  }
  
  .loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  </style>
  