<template>
    <div class="form-group">
        <label :for="id" class="form-label fw-bolder mb-0">{{titulo}}</label>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        props: ['id', 'titulo']
    }
</script>
